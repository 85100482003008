import pick from 'lodash/pick';
import omit from 'lodash/omit';
import compose from 'lodash/fp/compose';
import get from 'lodash/get';
import fetchApi from 'utils/fetch';
import * as types from 'types/booking';
import { CONFIRM_KICC_APPROVAL_FAILURE } from 'types/booking';
import { debug } from 'utils/debug';
import { fetchCartStatus } from 'features/ShoppingCart/utils/bookingStatusPolling';
import { BookingRequestSchema, BulkBookingRequestSchema } from '@kouto/types';
import * as helpers from './helpers';
import { getDeviceType } from '../utils';

export const setBooking = (values) => ({
  type: types.SET_BOOKING,
  payload: values,
});

export const resetBooking = () => ({
  type: types.RESET_BOOKING,
});

/**
 * Create
 */
const createBookingRequest = ({ booking, options }) => ({
  type: types.CREATE_BOOKING_REQUEST,
  payload: {
    booking,
    options,
  },
});

const createBookingSuccess = ({ booking, response }) => ({
  type: types.CREATE_BOOKING_SUCCESS,
  payload: {
    booking,
    response,
  },
});

const bookingFields = [
  'sessionTime',
  'purchaser',
  'participants',
  'mode',
  'notes',
  'duration',
  'couponCode',
  'accessCode',
  'paymentMethod',
  'paymentDetail',
];

const bookingParticipantFields = [
  'firstName',
  'lastName',
  'emailAddress',
  'phoneNumber',
  'priceTierName',
  'customQuestionResponses',
];

export const filter = (body) => {
  const result = pick(body, bookingFields);
  result.purchaser = pick(body, bookingParticipantFields);
  result.participants = result.participants.map((participant) => {
    return pick(participant, bookingParticipantFields);
  });
  return result;
};

export const createBooking = (experienceId, body) => async (dispatch) => {
  const filters = compose(filter, helpers.filterEmpty);
  const bookingData = {
    ...filters(body),
    bookingScriptUrl: window.location.href,
    deviceType: getDeviceType(),
  };

  const { success } = BookingRequestSchema.safeParse(bookingData);

  if (!success) {
    return dispatch(
      helpers.errorAction(
        types.CREATE_BOOKING_FAILURE,
        'Invalid booking data!',
      ),
    );
  }

  dispatch(createBookingRequest({ booking: bookingData }));
  try {
    const { data } = await fetchApi
      .post(`v1/experiences/${experienceId}/book`, {
        json: bookingData,
      })
      .json();
    return dispatch(createBookingSuccess({ booking: body, response: data }));
  } catch (errorResponse) {
    debug('error', { createBookingError: errorResponse });
    return dispatch(
      helpers.errorAction(types.CREATE_BOOKING_FAILURE, errorResponse),
    );
  }
};

const bulkBookingFields = [
  'purchaser',
  'data',
  'couponCode',
  'accessCode',
  'paymentMethod',
  'paymentDetail',
  'additionalCustomQuestionResponses',
];

const bulkBookingParticipantFields = [
  'firstName',
  'lastName',
  'priceTierName',
  'emailAddress',
  'phoneNumber',
  'customQuestionResponses',
];

const integrationsFields = ['integrationType', 'validationData'];
const pmsValidationFields = [
  'reservationId',
  'reservationCode',
  'confirmationId',
];

export const formatBulkBookingCartItemsData = (cartItems, couponCode) => {
  return cartItems.map((item) => {
    const dicountedExperienceIds = couponCode?.experienceIds || [];
    const isExperienceDiscounted = dicountedExperienceIds.includes(
      item.experienceId,
    );

    return {
      mode: item.bookingMode,
      experienceId: item.experienceId,
      notes: item.notes,
      sessionDuration: item.sessionDuration,
      resourceGroupId: item.groupId,
      resourceQuantity: item.groupId ? item.participants.length : undefined,
      sessionTime: item.sessionDateTime,
      participants: item.participants.map((p) => {
        return pick(p, bulkBookingParticipantFields);
      }),
      additionalCustomQuestionResponses: item.additionalCustomQuestionResponses,
      ...(item.accessCode && {
        accessCode: item.accessCode,
      }),
      ...(isExperienceDiscounted && {
        couponCode: couponCode.code,
      }),
      ...(item.waitlistEntryId && {
        waitlistEntryId: item.waitlistEntryId,
      }),
      ...(item?.addOns?.length && {
        listingAddOns: item.addOns.map((addOn) => ({
          listingAddOnId: addOn.id,
          quantity: addOn.selectedNumber,
        })),
      }),
    };
  });
};

const formatBulkBookingBody = (body) => {
  const result = pick(body, [...bulkBookingFields, ...integrationsFields]);
  result.purchaser = pick(result.purchaser, bookingParticipantFields);
  if (result.paymentDetail) {
    result.validationData = pick(result.validationData, pmsValidationFields);

    result.paymentDetail = {
      pmsType: result.integrationType,
      ...result.paymentDetail,
      ...result.validationData,
      reservationId: result.validationData.reservationId,
      reservationCode:
        result.paymentDetail.reservationCode ||
        result.validationData.confirmationId,
    };

    if (result.paymentMethod === 'member-number') {
      result.paymentDetail = pick(result.paymentDetail, [
        'lastName',
        'methodNumber',
      ]);
    }
  }
  return omit(result, [...integrationsFields, 'couponCode']);
};

const createBulkBookingRequest = ({ booking, options }) => ({
  type: types.CREATE_BULK_BOOKING_REQUEST,
  payload: {
    booking,
    options,
  },
});

const createBulkBookingSuccess = ({ booking, response }) => ({
  type: types.CREATE_BULK_BOOKING_SUCCESS,
  payload: {
    booking,
    response,
  },
});

export const createBulkBooking =
  (brandId, cartId, body) => async (dispatch) => {
    const formatFN = compose(formatBulkBookingBody, helpers.filterEmpty);

    const bulkBookingData = {
      ...formatFN(body),
      bookingScriptUrl: window.location.href,
      deviceType: getDeviceType(),
      cartId,
    };
    const { success, error } =
      BulkBookingRequestSchema.safeParse(bulkBookingData);

    debug('error', 'parsing error', { error });

    if (!success) {
      debug('error', 'parsing error', { error, bulkBookingData });
      return dispatch(
        helpers.errorAction(
          types.CREATE_BULK_BOOKING_FAILURE,
          'Invalid booking data!',
        ),
      );
    }

    dispatch(createBulkBookingRequest({ booking: bulkBookingData }));
    try {
      const { data } = await fetchApi
        .post(`v1/brands/${brandId}/book-bulk`, {
          json: bulkBookingData,
        })
        .json();

      return dispatch(
        createBulkBookingSuccess({ booking: body, response: data }),
      );
    } catch (errorResponse) {
      const errAction = helpers.errorAction(
        types.CREATE_BULK_BOOKING_FAILURE,
        errorResponse,
      );

      const { experiencesErr, paymentMethod } =
        helpers.getResponseJson(errorResponse)?.errors ?? {};

      if (errAction.error === 'Something went wrong') {
        const result = await fetchCartStatus({ cartId });

        return result
          ? dispatch(createBookingSuccess({ booking: body, response: {} }))
          : dispatch({
              ...errAction,
              cartItemsError: paymentMethod?.error
                ? [paymentMethod.error]
                : experiencesErr,
            });
      }

      debug('error', { createBulkBookingError: errorResponse, body });

      return dispatch({
        ...errAction,
        cartItemsError: paymentMethod?.error
          ? [paymentMethod.error]
          : experiencesErr,
      });
    }
  };

export const resetCartError = () => ({
  type: types.RESET_CART_ERROR,
});

const createPMSValidationRequest = () => ({
  type: types.CREATE_PMS_VALIDATION_REQUEST,
});

const createPMSValidationSuccess = ({ response }) => ({
  type: types.CREATE_PMS_VALIDATION_SUCCESS,
  payload: {
    response,
  },
});

export const validateRoomChargeAction =
  ({ brandId, payload, integrationType }) =>
  async (dispatch) => {
    dispatch(createPMSValidationRequest());
    try {
      const { data } = await fetchApi
        .post(`v1/brands/${brandId}/room-charge/${integrationType}/validate`, {
          json: {
            ...(payload.methodNumber && { roomNumber: payload.methodNumber }),
            ...omit(payload, 'methodNumber'),
          },
        })
        .json();
      if (!data) {
        return dispatch(
          helpers.errorAction(types.CREATE_PMS_VALIDATION_FAILURE, 'error'),
        );
      }

      return dispatch(createPMSValidationSuccess({ response: data }));
    } catch (errorResponse) {
      return dispatch(
        helpers.errorAction(types.CREATE_PMS_VALIDATION_FAILURE, errorResponse),
      );
    }
  };

const getBookingContextRequest = () => ({
  type: types.GET_BOOKING_CONTEXT_REQUEST,
});

const getBookingContextSuccess = ({ response }) => ({
  type: types.GET_BOOKING_CONTEXT_SUCCESS,
  payload: {
    response,
  },
});

export const getBookingContext = (cartId) => async (dispatch) => {
  dispatch(getBookingContextRequest());
  try {
    const { data } = await fetchApi.get(`v1/carts/${cartId}`).json();
    if (!data) {
      return dispatch(
        helpers.errorAction(types.GET_BOOKING_CONTEXT_FAILURE, 'error'),
      );
    }
    return dispatch(getBookingContextSuccess({ response: data }));
  } catch (errorResponse) {
    return dispatch(
      helpers.errorAction(types.GET_BOOKING_CONTEXT_FAILURE, errorResponse),
    );
  }
};

export const confirmKICCApproval = async ({ brandId, ...approvalMeta }) => {
  try {
    const { data } = await fetchApi
      .post(`v1/brands/${brandId}/integrations/kicc/approve`, {
        json: {
          ...approvalMeta,
        },
      })
      .json();

    return {
      status: data.status,
      error: null,
    };
  } catch (errorResponse) {
    return Promise.resolve({
      status: CONFIRM_KICC_APPROVAL_FAILURE,
      error: errorResponse.message,
    });
  }
};
