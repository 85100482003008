import React, { FC, useMemo, useState } from 'react';
import moment from 'moment';
import { PriceTier } from '@kouto/types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Flex, Sep } from 'components/common/styled/common-styled';
import { PrimaryButton, SecondaryButton } from 'components/theme/Button/Button';
import { ResourceParagraph } from 'components/theme/Typography/ResourceTypography';
import { getSessionStartEndTime } from 'utils/formatDuration';
import { PriceTierRadioSelector } from 'components/Booking/PriceTier';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import {
  BottomDrawer,
  BottomDrawerProps,
} from 'components/BottomDrawer/BottomDrawer';
import ResourceMetaMobileView from './ResourceMeta/ResourceMetaMobileView';
import ResourceAccessCode, { AccessCodeHeading } from './ResourceAccessCode';

interface Props extends BottomDrawerProps {
  isLoading: boolean;
  priceTiers: PriceTier[];
  onAddToCart: () => void;
  onCheckout: () => void;
  onValidateAccessCode: (code: string) => void;
  onContinue?: () => void;
  selectedSession: {
    startDateTime: string;
    duration: number;
  };
  selectedPriceTierId?: string;
  onTierSelect: (tierId: string) => void;
  accessCodeVerified: boolean;
  accessCodeError: string;
}

const BottomSheetResourcePage: FC<Props> = ({
  priceTiers,
  onAddToCart,
  onCheckout,
  onContinue,
  selectedSession,
  selectedPriceTierId,
  onTierSelect,
  accessCodeVerified,
  onValidateAccessCode,
  accessCodeError,
  ...props
}) => {
  const currency = useBrandCurrency();
  const { t: translate } = useTranslation();

  const { startTime, endTime } = useMemo(() => {
    return getSessionStartEndTime(selectedSession);
  }, [selectedSession]);

  if (!currency) {
    return null;
  }

  if (!accessCodeVerified) {
    return (
      <BottomDrawer {...props} heading={<AccessCodeHeading />}>
        <ResourceAccessCode
          errorMessage={accessCodeError}
          onContinue={onValidateAccessCode}
          withHeading={false}
          isExclusive
        />
      </BottomDrawer>
    );
  }

  return (
    <BottomDrawer {...props}>
      <Flex
        direction="column"
        alignItem="flex-start"
        justifyContent="flex-start"
        gap={24}
      >
        <Flex
          direction="column"
          gap={16}
          alignItem="flex-start"
          justifyContent="flex-start"
          width="100%"
        >
          <ButtonWrapper
            direction="row"
            gap={16}
            alignItem="stretch"
            justifyContent="flex-start"
            width="100%"
          >
            {onContinue && (
              <PrimaryButton big onClick={onContinue}>
                {translate('continue')}
              </PrimaryButton>
            )}
            {!onContinue && (
              <>
                <SecondaryButton onClick={onAddToCart}>
                  {translate('addToCart')}
                </SecondaryButton>
                <PrimaryButton big onClick={onCheckout}>
                  {translate('checkout')}
                </PrimaryButton>
              </>
            )}
          </ButtonWrapper>
        </Flex>
        <ResourceMetaMobileView
          metaLabel="Date"
          value={moment(selectedSession.startDateTime).format('MMMM D, YYYY')}
        />
        <ResourceMetaMobileView
          metaLabel={translate('time')}
          value={`${startTime} - ${endTime}`}
        />
        <Sep />
        <Flex
          direction="column"
          alignItem="flex-start"
          justifyContent="flex-start"
          gap={16}
        >
          <ResourceParagraph
            className="resource-page-bottom-sheet__selected-tiers"
            fontWeight="350"
            lineHeight="normal"
          >
            {translate('tier')}
          </ResourceParagraph>
          <PriceTierRadioSelector
            value={selectedPriceTierId}
            priceTiers={priceTiers}
            currency={currency.code || ''}
            onChange={onTierSelect}
          />
        </Flex>
      </Flex>
    </BottomDrawer>
  );
};

const ButtonWrapper = styled(Flex)`
  #kouto-embed-root && {
    margin-top: 24px;

    & > button {
      flex: 1;
      padding: 10px 12px;
      height: 48px;
    }
  }
`;

export default BottomSheetResourcePage;
