import React, { FC, useState } from 'react';
import { CalendarProps } from 'react-calendar';
import styled from 'styled-components';
import moment from 'moment';
import BottomSheetDateSelector from 'components/Resource/BottomSheetDateSelector';
import FilledCalendarIcon from 'assets/calendar';
import { useTranslation } from 'react-i18next';
import { BottomDrawer } from './BottomDrawer';
import { IconSpan } from '../DatePicker/DateSelector';
import { SelectTriggerButton } from './SelectTriggerButton';

interface Props extends Omit<CalendarProps, 'value'> {
  availableDates: string[];
  displayFormat?: string;
  onMonthChange: (date: moment.Moment) => void;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
  value: string;
}

export const BottomSheetDatePicker: FC<Props> = ({
  availableDates,
  displayFormat = 'MMM Do',
  onChange,
  onMonthChange,
  onOpenChange,
  open = false,
  ...props
}) => {
  const { t } = useTranslation();

  const handleChange: CalendarProps['onChange'] = (option, event) => {
    onOpenChange?.(false);
    onChange?.(option, event);
  };

  return (
    <>
      <SelectTriggerButton
        aria-label={t('selectDate')}
        leftIcon={
          <IconSpan>
            <FilledCalendarIcon />
          </IconSpan>
        }
        onClick={() => onOpenChange?.(true)}
      >
        <SessionDateSpan>
          {moment(props.value ?? props.activeStartDate ?? undefined).format(
            displayFormat,
          )}
        </SessionDateSpan>
      </SelectTriggerButton>
      <BottomDrawer
        size="full"
        open={open}
        heading="Date"
        onClose={() => onOpenChange?.(false)}
      >
        <BottomSheetDateSelector
          availableDates={availableDates}
          onMonthChange={onMonthChange}
          {...props}
          onChange={handleChange}
        />
      </BottomDrawer>
    </>
  );
};

const SessionDateSpan = styled.span`
  @media screen and (max-width: 395px) {
    font-size: 12px;
  }
`;

export default BottomSheetDateSelector;
