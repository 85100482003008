import { uuidv4 } from 'utils';

interface CachedContent {
  data: any; // yeah I know  |  Seriously bro ??
  timestamp: number;
}

const BASE_TTL = 30_000; // TTL in milliseconds - 1 minute
const SESSION_TTL = 5_000;

const ignoreCache = (key: string) => {
  // not used now, but we can define some rules to ignore cache for some requests
  return false;
};

const getTTL = (key: string) => {
  if (key.includes('sessions')) {
    return SESSION_TTL;
  }

  return BASE_TTL;
};

class RequestCacheClass {
  cache: Record<string, CachedContent> = {};

  private cacheKeys = new Set<string>();

  private cacheId: string;

  private static _instance: RequestCacheClass;

  constructor() {
    const cacheId = uuidv4();
    this.cacheId = cacheId;
  }

  public static get instance(): RequestCacheClass {
    if (!RequestCacheClass._instance) {
      RequestCacheClass._instance = new RequestCacheClass();
    }

    return RequestCacheClass._instance;
  }

  public get<T>(key: string): T | undefined {
    if (!ignoreCache(key) && this.cacheKeys.has(key)) {
      if (Date.now() - this.cache[key].timestamp < getTTL(key)) {
        return this.cache[key].data;
      }
      this.deleteCache(key);
    }

    return undefined;
  }

  public set<T>(key: string, value: T): void {
    this.cacheKeys.add(key);
    this.cache[key] = {
      timestamp: Date.now(),
      data: value,
    };
  }

  private deleteCache(key: string): void {
    this.cacheKeys.delete(key);
    delete this.cache[key];
  }
}

export const RequestCache = RequestCacheClass.instance;

export function cacheRequest<T>(
  key: string,
  request: () => Promise<T>,
): Promise<T> {
  const cachedValue = RequestCache.get<T>(key);
  if (cachedValue) {
    return Promise.resolve(cachedValue);
  }

  return request().then((value) => {
    RequestCache.set(key, value);
    return value;
  });
}
