import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import isObject from 'lodash/isObject';
import { FormProvider, useFieldArray, useForm } from 'hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Wrapper } from 'components/theme/Wrapper';
import { useAppState, useBrandId, useDispatch } from 'AppProvider';
import { fetchBrandTerms } from 'actions/brand';
import { BookingMode } from 'types/payment';
import { P as Paragraph } from 'components/theme/Typography/Typography';
import { SkeletonLine } from 'components/theme/Skeleton/SkeletonLine';
import { NavigationHeader } from 'components/NavigationHeader';
import { PrimaryButton } from 'components/theme/Button/Button';
import { addItemToWaitlist, cleanWaitlist } from 'actions/waitlist';
import useSearchQueryParams from 'hooks/use-search-params';
import {
  getCartIneligibleError,
  initializeFormFields,
} from 'features/ShoppingCart/utils';
import useParticipants from 'features/ShoppingCart/hooks/useParticipants';
import { ParticipantField } from 'features/ShoppingCart/components/ParticipantField';
import {
  IParticipantConfig,
  IParticipantResult,
  ISelectOptions,
} from 'features/ShoppingCart/types';
import useOneListing from 'hooks/useOneListing';
import useFetchWaitlist from 'selectors/waitlist';
import {
  formatDateForReq,
  formatUTCDate,
  ISO_DATE_FORMAT,
  isSessionMultipleTiers,
  uuidv4,
} from 'utils';
import { useToastContext } from 'ToastProvider';
import { IWaitlistState } from 'types/waitlist';
import ListingCartItemSummary from 'features/ShoppingCart/components/ListingCartItemSummary';
import { getExperienceSettings } from 'actions/experience';
import * as Styled from 'features/ShoppingCart/utils/styles';
import useFetchExperience from 'selectors/experience';
import { QUESTION_SCOPES } from 'types/custom-questions';
import CustomQuestions from 'components/CustomQuestion/CustomQuestions';
import { STATUS_PENDING } from 'types/app';
import useSelectedParticipants from 'selectors/participants';
import { ANALYTICS_EVENT } from '@kouto/types';
import { usePageViewEvent } from 'hooks/usePageViewEvent';
import { useTotalCartAmountTracker } from 'hooks/useTotalCartAmountTracker';
import useTrackCartParticipants from 'features/ShoppingCart/hooks/useTrackCartParticipants';
import { useExperienceAnalyticsData } from 'features/analytics/hooks/useExperienceAnalyticsData';
import { useEmbedConfig } from 'features/EmbedConfig';
import { CartItemState } from 'types/cart';

const formatFullName = (name: string | ISelectOptions) => {
  if (typeof name === 'string') {
    return name.trim();
  }
  return {
    label: name?.label.trim(),
    value: name?.value,
  };
};

// This page is the equivalent of the "participants page" for the waitlist flow
const WaitlistForm = () => {
  const { currentExperienceDetail } = useExperienceAnalyticsData();
  useTotalCartAmountTracker();
  useTrackCartParticipants();
  const dispatch = useDispatch();
  const brandId = useBrandId();
  const methods = useForm<IParticipantConfig>({ mode: 'onChange' });
  const history = useHistory();
  const { t: translateText } = useTranslation();
  const { searchParams } = useSearchQueryParams();
  const { settings } = useAppState(
    (state: Record<string, unknown>) => state.brand,
  );
  const { showToast } = useToastContext();

  useFetchWaitlist();

  const {
    item: waitlistItem,
    experience: waitlistExperience,
    isFetchingExperience,
  }: IWaitlistState = useAppState(
    (state: Record<string, unknown>) => state.waitlist,
  );

  const { experience, fetchExperienceStatus, fetchSettingsStatus } =
    useFetchExperience();

  const { listing } = useOneListing(searchParams?.listingId);

  usePageViewEvent({
    eventName: ANALYTICS_EVENT.VIEW_PARTICIPANT_INFO,
    isNotReady: !currentExperienceDetail?.id,
  });

  const { filteredSelectedCustomers, selectedTierCustomers, groupTitle } =
    useSelectedParticipants();

  const { accessCode } = useAppState(
    (state: Record<string, unknown>) => state.accessCode,
  );
  const selectedParticipants = useParticipants(filteredSelectedCustomers);

  const embedConfig = useEmbedConfig();

  const { fields, append } = useFieldArray({
    control: methods.control,
    name: 'participants',
  });

  const sessionDateTime = formatUTCDate(
    searchParams.sessionDate,
    searchParams.sessionTime,
  );

  const cartEligibilityParams = {
    addedItem: {
      sessionDateTime,
      experience,
      duration: searchParams.duration,
    },
    experienceList: waitlistExperience ? [waitlistExperience] : [],
    cartExperienceItems: waitlistItem ?? {},
    ontoggle,
  };

  const participantsInfoMapper = (data: IParticipantConfig['participants']) => {
    return data.map((p) => {
      const name = isObject(p.fullName) ? p.fullName.label : p.fullName;
      const splittedName = name.split(' ');
      return {
        ...p,
        fullName: name,
        firstName: splittedName[0],
        lastName: splittedName.slice(1).join(' '),
        price: p.price,
        customQuestionResponses: p.customQuestionResponses,
        ...(p.emailAddress && { emailAddress: p.emailAddress }),
        ...(p.phoneNumber && { phoneNumber: p.phoneNumber }),
      };
    });
  };

  const onDispatchCartAction = async (data: IParticipantConfig) => {
    const { sessionDate, sessionTime, duration } = searchParams || {};
    if (!sessionDate || !sessionTime || !duration) {
      return;
    }
    const { cartItemMetadata } = accessCode || {};

    dispatch(
      addItemToWaitlist({
        item: {
          cartItemId: uuidv4(),
          state: CartItemState.READY,
          sessionDateTime: formatDateForReq(
            `${sessionDate}T${sessionTime}`,
            ISO_DATE_FORMAT,
          ),
          sessionDuration: searchParams.duration || '',
          experienceId: experience.id,
          listingId: data.listingId,
          bookingMode: searchParams.mode || BookingMode.SHARED,
          notes: data.notes,
          participants: participantsInfoMapper(data.participants),
          ...(cartItemMetadata?.experienceId === experience.id &&
            cartItemMetadata.sessionDateTime === sessionDateTime && {
              accessCode: accessCode.code,
            }),
          additionalCustomQuestionResponses:
            data.additionalCustomQuestionResponses,
        },
      }),
    );
  };

  const populateFormFields = () => {
    if (fields.length >= 1) return;
    append(
      initializeFormFields(
        selectedParticipants as IParticipantResult[],
        embedConfig,
      ),
      {
        focusIndex: 0,
      },
    );
  };

  useEffect(() => {
    if (!experience.loading && brandId) {
      dispatch(fetchBrandTerms(brandId));
      populateFormFields();
      dispatch(getExperienceSettings(brandId, experience?.id));
    }
  }, [experience.id, experience.loading, brandId]);

  useEffect(() => {
    // This "participants page" for waitlist receives all its params from the url, and at the beginning there is no waitlistItem in the redux state.
    // So if we have one, it means that the page submit method has been called, and we can go to the next step.
    if (waitlistItem && waitlistExperience) {
      history.push({
        pathname: '/join-waitlist',
        search: `?${new URLSearchParams(searchParams).toString()}`,
      });
    }
  }, [waitlistItem, waitlistExperience, searchParams, history]);

  const handleSubmit = (retry = false) => {
    return methods.handleSubmit(
      (data) => {
        const dataCopy = {
          ...data,
          state: CartItemState.READY,
          participants: data.participants.map((participant) => ({
            ...participant,
            fullName:
              searchParams.skipParticipantsName === 'true'
                ? 'Resource Purchaser'
                : formatFullName(participant.fullName),
          })),
        };
        if (searchParams.groupId && groupTitle) {
          dataCopy.groupTitle = groupTitle;
        }
        if (searchParams.groupId) {
          dataCopy.groupId = experience.resourceGroupId || searchParams.groupId;
        }
        if (searchParams.listingId) {
          dataCopy.listingId = searchParams.listingId;
        }

        const cartErrorMessage = getCartIneligibleError(
          cartEligibilityParams,
          fetchExperienceStatus,
        );
        if (cartErrorMessage) {
          showToast({
            title: 'Failure',
            message: cartErrorMessage,
            type: 'failure',
          });
          return;
        }

        onDispatchCartAction(dataCopy);
      },
      (errors, ev) => {
        // Handle weird react-hook-form bug where participants error array is empty
        // https://github.com/react-hook-form/react-hook-form/issues/9902
        const { participants: participantsErrors } = errors;
        if (
          !retry &&
          Array.isArray(participantsErrors) &&
          !participantsErrors.find((err) => !!err)
        ) {
          methods.clearErrors();
          requestAnimationFrame(() => handleSubmit(true)(ev));
        }
      },
    );
  };

  const onGoBack = () => {
    dispatch(cleanWaitlist(undefined));
  };

  return (
    <Wrapper>
      <Helmet>
        {experience?.title && (
          <title>
            {experience?.title} | Details | {settings?.name}
          </title>
        )}
      </Helmet>
      <NavigationHeader onGoBack={onGoBack} />
      {filteredSelectedCustomers.length > 0 && (
        <Styled.FormWrapper>
          <div>
            {experience?.loading ||
            isFetchingExperience ||
            fetchSettingsStatus === STATUS_PENDING ? (
              <>
                <SkeletonLine style={{ height: 100 }} translucent />
                <SkeletonLine style={{ height: 300 }} translucent />
              </>
            ) : (
              <>
                <Styled.HeadTitle>
                  {translateText('participantDetails')}
                </Styled.HeadTitle>
                <Paragraph>
                  {translateText('enterParticipantInfoText')}
                </Paragraph>
                <FormProvider {...methods}>
                  <Styled.FormSection>
                    {searchParams.skipParticipantsName === 'true'
                      ? null
                      : fields.map((field, i) => {
                          return (
                            <ParticipantField
                              idx={i}
                              priceTierName={field.priceTierName}
                              fieldsLength={fields.length}
                              key={field.id}
                            />
                          );
                        })}
                    <CustomQuestions
                      scope={QUESTION_SCOPES.PURCHASER}
                      formStatePath="additionalCustomQuestionResponses"
                    />
                    <Styled.FooterButtons>
                      <PrimaryButton
                        className="cart__form-button-full cart__proceed-to-checkout-button"
                        disabled={
                          String(searchParams.latest).toLowerCase() === 'true'
                        }
                        onClick={handleSubmit()}
                        analyticEvent={
                          ANALYTICS_EVENT.CLICK_PROCEED_TO_CHECKOUT_BUTTON
                        }
                      >
                        {translateText('continue')}
                      </PrimaryButton>
                    </Styled.FooterButtons>
                  </Styled.FormSection>
                </FormProvider>
              </>
            )}
          </div>
          <div>
            {listing && (
              <ListingCartItemSummary
                cartItem={{
                  cartItemId: '',
                  bookingMode: BookingMode.SHARED,
                  participants: [],
                  sessionDateTime: `${searchParams.sessionDate}T${searchParams.sessionTime}`,
                  sessionDuration: searchParams.duration,
                  additionalCustomQuestionResponses: {},
                  listingId: searchParams.listingId,
                  experienceId: experience.id,
                  state: CartItemState.READY,
                  groupId: searchParams.groupId,
                  addOns: [],
                }}
                listing={listing}
                overriddenParticipants={fields.map((p) => ({
                  firstName: '',
                  lastName: '',
                  fullName: '',
                  priceTierName: p.priceTierName,
                  price: p.price,
                  terms: p.terms,
                  customQuestionResponses: p?.customQuestionResponses ?? {},
                }))}
              />
            )}
          </div>
        </Styled.FormWrapper>
      )}
    </Wrapper>
  );
};

export default WaitlistForm;
