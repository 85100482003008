import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import get from 'lodash/get';
import flow from 'lodash/fp/flow';
import groupBy from 'lodash/fp/groupBy';
import sortBy from 'lodash/fp/sortBy';
import orderBy from 'lodash/fp/orderBy';
import find from 'lodash/fp/find';
import filter from 'lodash/fp/filter';
import reduce from 'lodash/fp/reduce';
import slice from 'lodash/fp/slice';
import uniqBy from 'lodash/fp/uniqBy';
import map from 'lodash/fp/map';
import formatDate from 'date-fns/fp/format';
import parseISO from 'date-fns/fp/parseISO';
import getWeek from 'date-fns/fp/getWeek';

import { useAppState, useBrandId, useDispatch } from 'AppProvider';

import Calendar, {
  getMonthStartDate,
  getMonthEndDate,
} from 'components/Calendar';
import LoadingIndicator from 'components/theme/LoadingIndicator';
import { ButtonBlock, Button } from 'components/theme/Button';
import { Wrapper } from 'components/theme/Wrapper';
import { SkeletonPulse } from 'components/theme/Skeleton';

import {
  getCalendarEvents,
  getCalendarFirstAvialbleDate,
  searchCategories,
  searchVibes,
} from 'actions/brand';

import {
  serializeParams,
  removeTimeZonePart,
  getRandomInteger,
  uuidv4,
} from 'utils';
import { STATUS_PENDING, STATUS_SUCCESS, STATUS_FAILURE } from 'types/app';
import { TYPE_ONE_TIME } from 'types/experience';
import { getExperiencePrice } from 'components/helpers';
import useCustomHistory from 'hooks/use-custom-history';
import useSearchQueryParams from 'hooks/use-search-params';

import { useIsMobile } from 'WindowDimensionProvider';
import { getSessionPriceTiers } from 'actions/experience';
import { ANALYTICS_EVENT } from '@kouto/types';
import { useAnalyticsReadyForQueryParams } from 'hooks/use-analytics-ready-for-query-params';
import { useLocation } from 'react-router-dom';
import { getListingCoverPictures } from 'utils/collections';
import StarIcon from './star.svg';
import {
  PrimaryColorText,
  ExperienceTitle,
  ExperienceHeadline,
  ExperiencePrice,
  TitleColor,
} from './Common';
import PreviewModal from './PreviewModal';
import { Filter } from './Filters';
import { usePageViewEvent } from '../../hooks/usePageViewEvent';

const formatDateForReq = (date) => {
  return formatDate('yyyy-MM-dd')(date);
};

export default function ExperiencesCalendar() {
  const isMobile = useIsMobile();
  const brandId = useBrandId();
  const dispatch = useDispatch();
  const { push: customPush } = useCustomHistory();
  const { searchParams, replaceParams } = useSearchQueryParams();
  const { t: translate } = useTranslation();

  const [currentMonth, setCurrentMonth] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [sessionsByDate, setSessionsByDate] = useState(null);
  const [randomFeaturedExperiences, setRandomFeaturedExperiences] =
    useState(null);
  const [currentSelectedExperience, setCurrentSelectedExperience] =
    useState(null);
  const [modalPosition, setModalPosition] = useState(null);
  const [expandedCell, setExpandedCell] = useState(null);
  const [mobileExpandedItem, setMobileExpandedItem] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [selectedVibes, setSelectedVibes] = useState(null);

  const isNotReady = useAnalyticsReadyForQueryParams();

  const [key, setKey] = useState('');
  const location = useLocation();

  useEffect(() => {
    setKey(uuidv4());
  }, [location.search]);

  usePageViewEvent({
    eventName: ANALYTICS_EVENT.VIEW_CALENDAR,
    isNotReady,
    key,
  });

  const {
    settings,
    fetchCalendarEventsStatus,
    fetchCalendarFirstAvailableDateStatus,
    calendarFirstAvailableDate,
    calendarSessions,
    calendarExperiences,
    calendarCollections,
    categories,
    vibes,
  } = useAppState((state) => state.brand);

  const brandName = get(settings, 'name');

  const dispatchEventsHelper = (date, query = {}) => {
    const fromDate = getMonthStartDate(date);
    const toDate = getMonthEndDate(date);
    dispatch(
      getCalendarEvents(
        brandId,
        formatDateForReq(fromDate),
        formatDateForReq(toDate),
        query,
      ),
    );
  };

  // TODO extract to separate hook
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      !searchParams?.categoryIds &&
      !searchParams?.vibeIds &&
      !searchParams?.fromDate
    ) {
      dispatch(
        getCalendarFirstAvialbleDate(brandId, {
          from: formatDateForReq(new Date()),
          pastDates: !isMobile,
        }),
      );
    }
    if (searchParams?.fromDate) {
      const parsedUrlDate = parseISO(searchParams.fromDate);
      setCurrentMonth(parsedUrlDate);
      setSelectedDate(parsedUrlDate);
    } else {
      const today = Date.now();
      setCurrentMonth(today);
      setSelectedDate(today);
    }

    if (searchParams.categoryIds) {
      setSelectedCategories(getCategoryArrayFromUrl(searchParams.categoryIds));
    }

    if (searchParams.vibeIds) {
      setSelectedVibes(getVibesArrayFromUrl(searchParams.vibeIds));
    }

    dispatch(searchCategories({}, { brandId }));
    dispatch(searchVibes({}, { brandId }));
    return function cleanup() {
      setSessionsByDate(null);
      setRandomFeaturedExperiences(null);
      setExpandedCell(null);
      setCurrentSelectedExperience(null);
    };
  }, []);

  const isFirstAvailableDateFetching = () => {
    return fetchCalendarFirstAvailableDateStatus === STATUS_PENDING;
  };
  useEffect(() => {
    if (
      (selectedVibes || selectedCategories || currentMonth) &&
      !isFirstAvailableDateFetching()
    ) {
      dispatchEventsHelper(currentMonth || new Date(), {
        vibeIds: selectedVibes,
        categoryIds: selectedCategories,
        pastDates: !isMobile,
      });
    }
  }, [selectedVibes, selectedCategories, currentMonth, isMobile]);

  useEffect(
    function changeCalendarMonthOnFirstAvailableDate() {
      if (
        fetchCalendarFirstAvailableDateStatus === STATUS_SUCCESS &&
        calendarFirstAvailableDate &&
        !searchParams?.fromDate
      ) {
        setCurrentMonth(parseISO(calendarFirstAvailableDate));
        replaceParams({
          ...searchParams,
          fromDate: formatDateForReq(parseISO(calendarFirstAvailableDate)),
        });
      }
      if (
        fetchCalendarFirstAvailableDateStatus === STATUS_FAILURE &&
        !calendarFirstAvailableDate &&
        !searchParams?.fromDate
      ) {
        setCurrentMonth(new Date());
      }
    },
    [calendarFirstAvailableDate, fetchCalendarFirstAvailableDateStatus],
  );

  const isOneTimeEvent = (experienceItem) => {
    return experienceItem?.type === TYPE_ONE_TIME;
  };

  const currency = get(settings, 'currency');

  useEffect(() => {
    if (!calendarExperiences?.length && !calendarCollections?.length) {
      setSessionsByDate(null);
      setRandomFeaturedExperiences(null);
      setExpandedCell(null);
      setCurrentSelectedExperience(null);
      return;
    }

    const experiencesMap = new Map();

    calendarCollections.forEach((collection) => {
      collection.resourceGroups.forEach((group) => {
        group.experiences.forEach((experience) => {
          experiencesMap.set(experience.id, {
            ...experience,
            category: collection.category,
            coverPicture:
              group.pictures?.[0] ?? getListingCoverPictures(collection)?.[0],
            currency,
            title: group.title,
            headline: collection.summary,
            collectionId: collection.id,
            groupId: group.id,
            collection,
          });
        });
      });
    });

    calendarExperiences.forEach((experience, index) => {
      experiencesMap.set(experience.id, {
        ...experience,
        order: index,
      });
    }, {});

    const calendarSessionsWithoutTimeZone = map((sessionItem) => {
      const exp = experiencesMap.get(sessionItem.experienceId);
      return {
        ...sessionItem,
        startDateTime: removeTimeZonePart(sessionItem.startDateTime),
        isPast: sessionItem.hasPassed,
        startDate: flow(
          removeTimeZonePart,
          parseISO,
          formatDateForReq,
        )(sessionItem.startDateTime),
        featuredOrder: exp?.order,
        isFeaturedItem: exp?.isFeatured,
        isOneTimeEvent: isOneTimeEvent(exp),
        week: flow(
          removeTimeZonePart,
          parseISO,
          getWeek,
        )(sessionItem.startDateTime),
        experience: exp,
        collectionId: exp?.collection?.id,
        groupId: exp?.groupId,
        kind: exp?.kind,
      };
    });

    const transformedSessions =
      calendarSessionsWithoutTimeZone(calendarSessions);

    const groupedExperiences = groupBy((sessionItem) => {
      return `${sessionItem.groupId ?? sessionItem.experienceId}-${
        sessionItem.startDateTime
      }`;
    }, transformedSessions);

    const sortedSessionsList = Object.values(groupedExperiences).map(
      (group) => {
        return Array.isArray(group) ? group[0] : group;
      },
    );

    const sessionsGrouped = groupBy((sessionItem) => {
      return formatDateForReq(parseISO(sessionItem.startDateTime));
    });

    const sessionsGroupedByWeek = groupBy((sessionItem) => {
      return sessionItem.week;
    })(sortedSessionsList);

    // randomly select one featured experience per week
    const randomFeaturedExperiencesMap = reduce((allItems, weekKey) => {
      const featuredItemsInWeek = filter((sessionItem) => {
        return sessionItem.isFeaturedItem;
      })(sessionsGroupedByWeek[weekKey]);
      if (!featuredItemsInWeek?.length) return allItems;
      const randomItemIndex = getRandomInteger(
        0,
        featuredItemsInWeek.length - 1,
      );
      const { experienceId } = featuredItemsInWeek[randomItemIndex];
      const { startDate } = featuredItemsInWeek[randomItemIndex];
      return {
        ...allItems,
        [startDate]: {
          experienceId,
          startDate,
        },
      };
    }, {})(Object.keys(sessionsGroupedByWeek));

    setRandomFeaturedExperiences(randomFeaturedExperiencesMap);

    setSessionsByDate(flow(sessionsGrouped)(sortedSessionsList));
  }, [calendarSessions, calendarExperiences, calendarCollections]);

  const getCategoryArrayFromUrl = (categoryIds) => {
    if (Array.isArray(categoryIds)) {
      return searchParams.categoryIds;
    }
    return [searchParams.categoryIds];
  };

  const getVibesArrayFromUrl = (vibeIds) => {
    if (Array.isArray(vibeIds)) {
      return searchParams.vibeIds;
    }
    return [searchParams.vibeIds];
  };

  useEffect(() => {
    if (searchParams?.categoryIds) {
      if (selectedCategories?.length) return;
      setSelectedCategories(getCategoryArrayFromUrl(searchParams.categoryIds));
    }
    if (searchParams?.vibeIds) {
      if (selectedVibes?.length) return;
      setSelectedVibes(getVibesArrayFromUrl(searchParams.vibeIds));
    }
  }, []);

  const handleMonthChange = (date) => {
    setSessionsByDate(null);
    setCurrentMonth(date);
    setSelectedDate(date);
    setExpandedCell(null);
    replaceParams({
      ...searchParams,
      fromDate: formatDateForReq(date),
    });
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setMobileExpandedItem(null);
    replaceParams({
      ...searchParams,
      fromDate: formatDateForReq(date),
    });
  };

  const getSessionsForADate = (currentDate) => {
    const experiences = get(
      sessionsByDate,
      `[${formatDateForReq(currentDate)}]`,
    );
    const uniqExperiences = uniqBy('experienceId');
    const sortByStartTimeDesc = orderBy(['startDateTime'], ['desc']);
    const filteredExps = flow(
      sortByStartTimeDesc,
      uniqExperiences,
    )(experiences);
    return orderBy(['startDateTime'], ['asc'])(filteredExps);
  };

  const onSeeMoreClick = (experience) => () => {
    customPush({
      pathname: `/experiences/${experience?.id}`,
      search: serializeParams({
        ...searchParams,
        previous: `/calendar`,
        fromDate: formatDateForReq(currentMonth),
        sessionDate: formatDateForReq(selectedDate),
      }),
    });
  };

  const getExperienceType = (experience) => {
    return get(experience, 'category.name');
  };

  const handleNavigateToCard = () => {
    customPush({
      pathname: `/experiences`,
      search: serializeParams({ lang: searchParams?.lang }),
    });
  };

  const getStartDateTimesForSelectedExperience = (experienceId, startDate) => {
    return get(sessionsByDate, startDate)
      .filter((session) => session.experienceId === experienceId)
      .map((s) => s.startDateTime);
  };

  const navRenderer = ({ isCartFeatureEnabled }) => {
    const handleFilterKeyDown = (filterName) => (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        // This should open the filter dropdown
        event.target.click();
      }
    };

    return (
      <NavigationContainer>
        <TypeVibeContainer>
          <FilterWrapper
            aria-haspopup="listbox"
            onKeyDown={handleFilterKeyDown('category')}
          >
            <Filter
              label="experienceType"
              name="category"
              placeholder="filterExperienceType"
              options={categories}
              onChange={(values) =>
                setSelectedCategories(values.map(({ value }) => value))
              }
              isMulti
              closeMenuOnSelect={false}
              useQueryParams
            />
          </FilterWrapper>
          <FilterWrapper
            aria-haspopup="listbox"
            onKeyDown={handleFilterKeyDown('vibe')}
          >
            <Filter
              label="experienceVibe"
              name="vibe"
              placeholder="filterExperienceVibe"
              options={vibes}
              onChange={(values) => {
                setSelectedVibes(values.map(({ value }) => value));
              }}
              isMulti
              closeMenuOnSelect={false}
              useQueryParams
            />
          </FilterWrapper>
        </TypeVibeContainer>

        {isCartFeatureEnabled === false && (
          <Switcher>
            <SearchAllButton onClick={handleNavigateToCard}>
              {translate('searchAll')}
            </SearchAllButton>
          </Switcher>
        )}
      </NavigationContainer>
    );
  };

  const getExperiencesMobileList = (currentDate) => {
    const sessionsForADate = getSessionsForADate(currentDate);
    if (
      sessionsForADate?.length === 0 &&
      fetchCalendarEventsStatus === STATUS_SUCCESS
    ) {
      return <NoExperiences>No available experiences</NoExperiences>;
    }
    return sessionsForADate?.map((session) => {
      const experience = get(session, 'experience');
      return (
        <ExperienceMobileListItem
          key={`${session.experienceId}-${session.startDateTime}`}
          onClick={() => {
            setMobileExpandedItem((state) =>
              state === session.experienceId ? null : session.experienceId,
            );
          }}
        >
          <ExperienceItemMobileImageContainer
            isFeatured={get(experience, 'isFeatured')}
          >
            <ExperienceItemImageMobile
              src={get(experience, `coverPicture.uri.384w`)}
            />
            <div>
              <PrimaryColorText className="calendar__experience-popup-hosted-by">
                {getExperienceType(experience)}
                {experience?.isExclusive && <ExclusiveIcon />}
              </PrimaryColorText>
              <ExperienceTitle>{get(experience, `title`)}</ExperienceTitle>
            </div>
          </ExperienceItemMobileImageContainer>
          <ExperienceItemMobileExpanded
            isExpanded={mobileExpandedItem === session.experienceId}
          >
            <ExperienceHeadline>
              {get(experience, 'headline')}
            </ExperienceHeadline>
            <ExperiencePrice>
              {getExperiencePrice(experience, translate)}
            </ExperiencePrice>
            <div>
              <SeeMoreButton type="button" onClick={onSeeMoreClick(experience)}>
                See more
              </SeeMoreButton>
            </div>
          </ExperienceItemMobileExpanded>
        </ExperienceMobileListItem>
      );
    });
  };

  const renderExperiencesItemsForMobile = () => {
    return (
      <MobileListContainer>
        <MobileListHeading>
          <SelectedDate>
            {formatDate('MMMM dd, yyyy')(selectedDate)}
          </SelectedDate>
          <MobileSeparator />
        </MobileListHeading>
        <MobileListView>
          {getExperiencesMobileList(selectedDate)}
        </MobileListView>
      </MobileListContainer>
    );
  };

  const featuredExperienceItemMapper = (session) => {
    const experience = get(session, 'experience');
    const handleExperienceSelect = (e) => {
      if (session.isPast) return;
      e.stopPropagation();
      const startDateTimes = getStartDateTimesForSelectedExperience(
        experience.id,
        session.startDate,
      );
      setModalPosition(e.target.getBoundingClientRect());
      setSelectedDate(parseISO(session.startDateTime));
      setCurrentSelectedExperience(experience);
      dispatch(
        getSessionPriceTiers(
          experience.id,
          Array.from(new Set(startDateTimes)),
        ),
      );
    };
    return (
      <ExperienceListItem
        key={`${session?.experienceId}-${session?.startDateTime}`}
      >
        <ExperienceItemImageContainer isFeatured isPast={session.isPast}>
          <ExperienceImageGradientContainer isPast={session.isPast}>
            <ExperienceItemFeaturedImage
              src={get(experience, `coverPicture.uri.384w`)}
            />
          </ExperienceImageGradientContainer>
          <ExperienceListTitle
            onClick={handleExperienceSelect}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                handleExperienceSelect(e);
              }
            }}
            isPast={session.isPast}
            tabIndex={session.isPast ? -1 : 0}
            role="button"
          >
            <PrimaryColorText
              className="calendar__experience-popup-hosted-by"
              isPast={session.isPast}
            >
              {getExperienceType(experience)}
              {experience?.isExclusive && <ExclusiveIcon />}
            </PrimaryColorText>
            <TitleColor isPast={session.isPast}>
              {get(experience, 'title')}
            </TitleColor>
          </ExperienceListTitle>
        </ExperienceItemImageContainer>
      </ExperienceListItem>
    );
  };

  const experienceItemMapper = (session) => {
    const experience = get(session, 'experience');
    const handleExperienceSelect = (e) => {
      if (session.isPast) return;
      e.stopPropagation();
      const startDateTimes = getStartDateTimesForSelectedExperience(
        experience.id,
        session.startDate,
      );
      setModalPosition(e.target.getBoundingClientRect());
      setSelectedDate(parseISO(session.startDateTime));
      setCurrentSelectedExperience(experience);
      dispatch(
        getSessionPriceTiers(
          experience.id,
          Array.from(new Set(startDateTimes)),
        ),
      );
    };
    return (
      <ExperienceListItem
        key={`${session.experienceId}-${session.startDateTime}`}
      >
        <ExperienceListTitle
          onClick={handleExperienceSelect}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              handleExperienceSelect(e);
            }
          }}
          isPast={session.isPast}
          tabIndex={session.isPast ? -1 : 0}
          role="button"
        >
          <PrimaryColorText
            className="calendar__experience-popup-hosted-by"
            isPast={session.isPast}
          >
            {getExperienceType(experience)}
            {experience?.isExclusive && <ExclusiveIcon />}
          </PrimaryColorText>
          <TitleColor
            className="calendar__experience-list-title"
            isPast={session.isPast}
          >
            {get(experience, 'title')}
          </TitleColor>
        </ExperienceListTitle>
      </ExperienceListItem>
    );
  };

  const getMoreTextFeatured = (currentDateExperiences) => {
    if (currentDateExperiences.length - 1 > 0) {
      return <>+{currentDateExperiences.length - 1} More</>;
    }
    return null;
  };

  const itemRenderer = ({ currentDate, isPastDate, isToday }) => {
    const currentDateSessions = getSessionsForADate(currentDate) ?? [];
    const currentFormattedDate = formatDateForReq(currentDate);
    const isCellExpanded = get(expandedCell, `[${currentFormattedDate}]`);
    const hasPassed = !isToday && isPastDate;
    if (isMobile) {
      if (hasPassed || !currentDateSessions?.length) return null;
      return (
        <EventsCount>
          <span>{currentDateSessions?.length}</span>
        </EventsCount>
      );
    }
    const featuredSessions = filter((session) => session.isFeaturedItem)(
      currentDateSessions,
    );

    const containsFeatured = find((session) => {
      return session?.isFeaturedItem;
    })(currentDateSessions);

    const firstFeatured = find((session) => {
      return session?.isFeaturedItem && session.featuredOrder === 0;
    })(currentDateSessions);

    const containsRandomFeatured = get(
      randomFeaturedExperiences,
      `[${currentFormattedDate}]`,
    );

    // get the first session one time event to be featured

    const firstOneTimeEventOfDate = find((session) => {
      return session?.isOneTimeEvent;
    })(currentDateSessions);

    if (firstOneTimeEventOfDate && !isCellExpanded) {
      const handleMoreTextClick = () => {
        setExpandedCell({
          ...expandedCell,
          [currentFormattedDate]: true,
        });
      };

      const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          handleMoreTextClick();
        }
      };
      return (
        <>
          {featuredExperienceItemMapper(firstOneTimeEventOfDate)}
          <MoreText
            className="calendar__experiences-list-more-text"
            onClick={() => {
              setExpandedCell({
                ...expandedCell,
                [currentFormattedDate]: true,
              });
            }}
            onKeyDown={handleKeyDown}
            isPast={hasPassed}
            tabIndex={hasPassed ? -1 : 0}
            role="button"
          >
            {getMoreTextFeatured(currentDateSessions)}
          </MoreText>
        </>
      );
    }

    if (containsRandomFeatured && !isCellExpanded) {
      const handleMoreTextClick = () => {
        setExpandedCell({
          ...expandedCell,
          [currentFormattedDate]: true,
        });
      };
      const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          handleMoreTextClick();
        }
      };
      return (
        <>
          {featuredSessions?.length > 1 &&
            featuredExperienceItemMapper(firstFeatured || containsFeatured)}
          {featuredSessions?.length === 1 &&
            featuredExperienceItemMapper(containsFeatured)}
          <MoreText
            className="calendar__experiences-list-more-text"
            onClick={() => {
              setExpandedCell({
                ...expandedCell,
                [currentFormattedDate]: true,
              });
            }}
            onKeyDown={handleKeyDown}
            isPast={hasPassed}
            tabIndex={hasPassed ? -1 : 0}
            role="button"
          >
            {getMoreTextFeatured(currentDateSessions)}
          </MoreText>
        </>
      );
    }

    if (currentDateSessions.length > 3 && !isCellExpanded) {
      const handleMoreTextClick = () => {
        setExpandedCell({
          ...expandedCell,
          [currentFormattedDate]: true,
        });
      };

      const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          handleMoreTextClick();
        }
      };
      return (
        <>
          {flow(slice(0, 3), map(experienceItemMapper))(currentDateSessions)}
          <MoreText
            className="calendar__experiences-list-more-text"
            onClick={() => {
              setExpandedCell({
                ...expandedCell,
                [currentFormattedDate]: true,
              });
            }}
            onKeyDown={handleKeyDown}
            isPast={hasPassed}
            tabIndex={hasPassed ? -1 : 0}
            role="button"
          >
            +{currentDateSessions.length - 3} More
          </MoreText>
        </>
      );
    }
    if (firstOneTimeEventOfDate) {
      const restSessionItems = filter(
        (session) =>
          session.experienceId !== firstOneTimeEventOfDate.experienceId,
      )(currentDateSessions);
      return (
        <>
          {featuredExperienceItemMapper(firstOneTimeEventOfDate)}
          {map(experienceItemMapper)(restSessionItems)}
        </>
      );
    }
    if (containsRandomFeatured && !firstOneTimeEventOfDate) {
      const experiencesSortedByFeatured =
        sortBy('featuredOrder')(currentDateSessions);
      const [firstFeaturedItemInList, ...restItems] =
        experiencesSortedByFeatured;
      if (!firstFeaturedItemInList) return null;
      return (
        <>
          {featuredExperienceItemMapper(firstFeaturedItemInList)}
          {map(experienceItemMapper)(restItems)}
        </>
      );
    }
    return map(experienceItemMapper)(currentDateSessions);
  };

  const hideModal = () => {
    setCurrentSelectedExperience(null);
  };

  return (
    <CalendarWrapper
      className="calendar__calender-wrapper"
      role="presentation"
      onKeyDown={hideModal}
      onClick={hideModal}
    >
      <Helmet>
        {brandName && <title>Experience Calendar | {brandName}</title>}
      </Helmet>
      <CalendarContainer>
        {currentMonth &&
          (fetchCalendarEventsStatus === STATUS_PENDING ||
            fetchCalendarFirstAvailableDateStatus === STATUS_PENDING) && (
            <LoadingIconContainer>
              <LoadingIndicator />
            </LoadingIconContainer>
          )}
        {currentMonth ? (
          <Calendar
            currentMonth={currentMonth}
            onMonthChange={handleMonthChange}
            onDateSelected={handleDateSelect}
            navRenderer={navRenderer}
            itemRenderer={itemRenderer}
          />
        ) : (
          <CalendarSkeleton />
        )}
      </CalendarContainer>
      {currentSelectedExperience && (
        <PreviewModal
          experience={currentSelectedExperience}
          currentMonth={formatDateForReq(currentMonth)}
          selectedDate={formatDateForReq(selectedDate)}
          modalPosition={modalPosition}
        />
      )}
      {isMobile && renderExperiencesItemsForMobile()}
    </CalendarWrapper>
  );
}

const CalendarWrapper = styled(Wrapper)`
  @media (max-width: 768px) {
    padding: 10px;
  }
  @media (min-width: 768px) {
    margin-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const CalendarContainer = styled.div`
  position: relative;
`;

const CalendarSkeleton = styled(SkeletonPulse)`
  height: 300px;
  @media (min-width: 768px) {
    height: 500px;
  }
`;

const LoadingIconContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const NoExperiences = styled.div`
  margin: 15px 0;
`;

const NavigationContainer = styled(Row)`
  justify-content: space-between;
  width: 100%;
`;

const TypeVibeContainer = styled(Row)`
  @media (max-width: 768px) {
    margin: 12px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

const MobileListContainer = styled.div`
  margin-top: 20px;
  padding: 0 10px;
`;

const MobileListHeading = styled(Row)`
  justify-content: flex-start;
  align-items: center;
`;

const MobileListView = styled(Column)``;

const ExperienceMobileListItem = styled.div`
  border-bottom: 0.5px solid var(--way-colors-borderColor);
  margin-bottom: 0;
  padding: 13.5px 0;
`;

const ExperienceItemMobileImageContainer = styled(Row)`
  align-items: center;
  border-left: ${({ isFeatured }) =>
    isFeatured ? '2px solid var(--way-colors-primaryColorShades-100)' : 'none'};
  padding-left: ${({ isFeatured }) => (isFeatured ? `8px` : '0')};
`;

const ExperienceItemImageMobile = styled.img`
  background-color: #eee;
  width: 49px;
  height: 39px;
  margin-right: 10px;
  object-fit: cover;
`;

const ExperienceItemMobileExpanded = styled.div`
  height: auto;
  max-height: ${({ isExpanded }) => (isExpanded ? '300px' : 0)};
  overflow: hidden;
  transition: max-height 0.1s ease-in;
`;

const SeeMoreButton = styled(ButtonBlock)`
  width: 100%;
  height: 35px;
  padding: 7px;
`;

const MobileSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
`;

const EventsCount = styled(Row)`
  height: 18px;
  width: 18px;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 11px !important;
  line-height: 13px !important;
  letter-spacing: normal !important;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--way-colors-primaryColorShades-100);
  color: var(--way-colors-primaryColorContrastShades-100);
`;

const SelectedDate = styled.div`
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 15px !important;
  letter-spacing: normal !important;
  margin-right: 5px;
  min-width: 95px;
  text-align: left;
`;

const ExperienceListItem = styled.div`
  margin-bottom: 10px;
`;

const ExperienceItemImageContainer = styled(Column)`
  border-left: ${({ isPast }) =>
    isPast
      ? '2px solid var(--way-colors-primaryColorShades-50)'
      : '2px solid var(--way-colors-primaryColorShades-100)'};
  padding-left: 8px;
`;

const ExperienceImageGradientContainer = styled.div`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    background: ${({ isPast }) =>
      isPast
        ? `linear-gradient(0deg, var(--way-palette-white-0), var(--way-palette-white-50))`
        : `transparent`};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

const ExperienceItemFeaturedImage = styled.img`
  background-color: #eee;
  max-width: 149px;
  width: 100%;
  height: 72px;
  object-fit: cover;
  margin-bottom: 7px;
`;

const ExperienceListTitle = styled(ExperienceTitle)`
  margin-top: 4px;
  cursor: ${({ isPast }) => (isPast ? 'not-allowed' : 'pointer')};
  color: ${({ isPast }) =>
    isPast
      ? 'var(--way-palette-black-50)'
      : 'var(--way-colors-primaryTextColor)'};
`;

const MoreText = styled(PrimaryColorText)`
  cursor: pointer;
`;

const Switcher = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: 768px) {
    justify-content: flex-start;
    align-items: center;
  }
`;

const SearchAllButton = styled(Button)`
  border-radius: 50px;
  padding: 8px 12px;
  font-size: 16px;
  border: none;
  margin-right: 10px;
`;

const ExclusiveIcon = styled(StarIcon)`
  margin-left: 2px;
  path {
    fill: var(--way-colors-primaryColorShades-100);
  }
`;

const FilterWrapper = styled.div``;
