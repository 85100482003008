import styled, { css } from 'styled-components';
import { Close } from '../../../Modal/Modal.styles';

export const ExperienceDescriptionImageWrapper = styled.div`
  width: 100%;
  position: relative;
  max-height: 450px;
  img {
    cursor: pointer;
    max-width: 100%;

    @media (hover: none) {
      cursor: unset;
      pointer-events: none;
    }
  }
`;

export const FullScreenImage = styled.div`
  position: fixed;
  justify-content: center;
  align-content: center;
  align-items: center;
  max-height: unset;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  padding: 40px;
  z-index: ${Number.MAX_SAFE_INTEGER};
  background: var(--way-palette-black-70);

  > * {
    z-index: +99;
  }

  picture {
    display: flex;
  }

  img {
    width: unset;
    height: unset;
    object-fit: contain;
    margin: auto;
    min-height: 250px;
    min-width: 250px;
    max-width: 80vw;
    max-height: 80vh;
  }

  .originalImage {
    opacity: 0;
    z-index: -1;
  }
`;

export const ExperienceDescriptionImage = styled.img<{ isPortrait?: boolean }>`
  object-fit: contain;
  height: ${({ isPortrait }) => (isPortrait ? 'auto' : '100%')};
  max-height: 450px;
  object-position: center;
`;

export const CloseIcon = styled(Close)`
  position: fixed;
  top: 20px;
  right: 20px;
  background: var(--way-palette-white-100);
  border-radius: 50%;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.3);
  }
`;
