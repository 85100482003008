import React, { useMemo } from 'react';
import { BOOKING_AVAILABILITY_MODE_PRIVATE } from 'types/experience';
import useResponsiveFontSize from 'hooks/use-responsive-font-size';
import {
  getCurrencyFromCode,
  currencyFormat,
  isExperienceComplimentary,
  getExperienceStarterPrice,
  getSessionStarterPrice,
  isSessionComplimentary,
  getMultipleSessionsStarterPrice,
} from 'utils';
import { isEqual } from 'lodash';
import { AVAILABLE_INTEGRATIONS } from 'types/brand';

export const getExperiencePrice = (experience, translateText) => {
  const currency = getCurrencyFromCode(experience?.currency);
  const experienceStarterPrice = getExperienceStarterPrice(experience);
  const singleTierExperience = experience?.priceTiers?.[0];

  if (experience?.hidePrice) return null;

  if (isExperienceComplimentary(experience)) {
    return <div>{translateText('complimentary')}</div>;
  }

  if (
    isEqual(
      experience?.bookingAvailabilityMode,
      BOOKING_AVAILABILITY_MODE_PRIVATE,
    )
  ) {
    return (
      <div>
        {currencyFormat(currency)(
          singleTierExperience?.price * experience?.maxParticipantsCount,
        )}
        &nbsp;
        {translateText('total')}
      </div>
    );
  }

  return experience?.priceTiers?.length > 1 ? (
    <div>
      {translateText('experienceStartingPrice', {
        price: currencyFormat(currency)(experienceStarterPrice?.price),
      })}
      &nbsp;/&nbsp;
      {experienceStarterPrice?.name}{' '}
    </div>
  ) : (
    <div>
      {currencyFormat(currency)(singleTierExperience?.price)}
      &nbsp;/&nbsp;
      {singleTierExperience?.name}
    </div>
  );
};

export const getSessionPrice = (session, experience, translateText) => {
  const currencySymbol = getCurrencyFromCode(experience?.currency);
  const sessionStarterPrice = getSessionStarterPrice(session);
  const singleTierSession = session?.priceTiers?.[0];

  if (experience?.hidePrice) return null;

  if (isSessionComplimentary(session?.priceTiers)) {
    return <div>{translateText('complimentary')}</div>;
  }

  if (
    isEqual(
      experience?.bookingAvailabilityMode,
      BOOKING_AVAILABILITY_MODE_PRIVATE,
    )
  ) {
    return (
      <div>
        {currencyFormat(currencySymbol)(
          singleTierSession?.price * experience?.maxParticipantsCount,
        )}
        &nbsp;
        {translateText('total')}
      </div>
    );
  }

  return session?.priceTiers?.length > 1 ? (
    <div>
      {translateText('experienceStartingPrice', {
        price: currencyFormat(currencySymbol)(sessionStarterPrice?.price),
      })}
      &nbsp;/&nbsp;
      {sessionStarterPrice?.name}{' '}
    </div>
  ) : (
    <div>
      {currencyFormat(currencySymbol)(singleTierSession?.price)}
      &nbsp;/&nbsp;
      {singleTierSession?.name}
    </div>
  );
};

export const getLowestSessionsPrice = (sessions, experience, translateText) => {
  const currency = getCurrencyFromCode(experience?.currency);
  const sessionsStarterPrice = getMultipleSessionsStarterPrice(sessions);

  if (experience?.hidePrice) return null;

  const priceTiers = sessions?.map((session) => session.priceTiers).flat();

  if (isSessionComplimentary(priceTiers)) {
    return <div>{translateText('complimentary')}</div>;
  }

  if (
    isEqual(
      experience?.bookingAvailabilityMode,
      BOOKING_AVAILABILITY_MODE_PRIVATE,
    )
  ) {
    return (
      <div>
        {currencyFormat(currency)(
          priceTiers?.[0]?.price * experience?.maxParticipantsCount,
        )}
        &nbsp;
        {translateText('total')}
      </div>
    );
  }

  return priceTiers?.length > 1 ? (
    <div>
      {translateText('experienceStartingPrice', {
        price: currencyFormat(currency)(sessionsStarterPrice?.price),
      })}
      &nbsp;/&nbsp;
      {sessionsStarterPrice?.name}{' '}
    </div>
  ) : (
    <div>
      {currencyFormat(currency)(priceTiers?.[0]?.price)}
      &nbsp;/&nbsp;
      {priceTiers?.[0]?.name}
    </div>
  );
};

export const getPaymentMethodIntegrationForBrand = (brandIntegrations) => {
  return brandIntegrations?.find(
    (integration) =>
      integration.type === AVAILABLE_INTEGRATIONS.OPERA ||
      integration.type === AVAILABLE_INTEGRATIONS.STAYNTOUCH ||
      integration.type === AVAILABLE_INTEGRATIONS.INFOR,
  );
};

export const isIntegratedPaymentMethodEnabledForBrand = (
  brandIntegrations,
  settings,
) => {
  return (
    !!getPaymentMethodIntegrationForBrand(brandIntegrations) &&
    (settings?.features?.active?.includes('integrations:opera') ||
      settings?.features?.active?.includes('integrations:stayntouch') ||
      settings?.features?.active?.includes('integrations:infor'))
  );
};

export const isIntegratedPreArrivalChargeEnabled = (brandIntegrations) => {
  const integration = getPaymentMethodIntegrationForBrand(brandIntegrations);

  if (!integration) {
    return false;
  }

  return integration.config?.enablePreArrivalCharges;
};

export const isIntegratedRoomChargeEnabledForExperience = (experience) => {
  const integrationMeta =
    experience?.integrationMeta ??
    experience?.resourceGroups?.[0]?.integrationMeta;

  return !!integrationMeta?.find(
    (im) =>
      (im.type === AVAILABLE_INTEGRATIONS.OPERA ||
        im.type === AVAILABLE_INTEGRATIONS.STAYNTOUCH ||
        im.type === AVAILABLE_INTEGRATIONS.INFOR) &&
      im.key === 'transactionCode',
  );
};
