import {
  AnalyticsClient,
  IAnalyticsDataPayload,
} from 'features/analytics/types/analytics';
import {
  TealiumClient,
  GTMClient,
  Ga4Client,
  WayGtmClient,
} from 'features/analytics/clients';
import { get, post } from 'utils/axios';
import { type AxiosResponse } from 'axios';
import {
  type IAnalyticsConfigEndpoint,
  AnalyticsConfigEndpoint,
  ANALYTICS_PROPERTY,
  IntegrationName,
  type IAnalyticsEventRequestSchema,
  AnalyticsEventEndpoint,
  Integrations,
  IntegrationGroup,
} from '@kouto/types';
import { ANALYTICS_EVENT } from '@kouto/types/src/api/integration/analytics';
import format from 'date-fns/fp/format';
import { debug } from 'utils/debug';
import { uuidv4 } from 'utils';

const STORAGE_TRACKING_ID = '__way_user_tracking_id';

export type AnalyticsDataObserver = (
  appData: IAnalyticsDataPayload,
  newPayload: Partial<IAnalyticsDataPayload>,
) => void;

class AnalyticsManager {
  private providers: AnalyticsClient[] = [
    new WayGtmClient({ type: IntegrationName.GA4_ANALYTICS }),
  ];

  private observers: AnalyticsDataObserver[] = [];

  private appData: IAnalyticsDataPayload = {};

  private isReady = false;

  private sessionId: string;

  private events: {
    eventName: ANALYTICS_EVENT;
    payload?: Record<string, string>;
  }[] = [];

  private static instance: AnalyticsManager;

  public static getInstance(): AnalyticsManager {
    if (!AnalyticsManager.instance) {
      AnalyticsManager.instance = new AnalyticsManager();
    }

    return AnalyticsManager.instance;
  }

  constructor() {
    this.sessionId = uuidv4();
  }

  // eslint-disable-next-line class-methods-use-this
  get userId(): string {
    const storedUser = localStorage.getItem(STORAGE_TRACKING_ID);
    if (storedUser) return storedUser;

    const userId = uuidv4();
    localStorage.setItem(STORAGE_TRACKING_ID, userId);
    return userId;
  }

  async setProviders(brandId: string) {
    this.appData[ANALYTICS_PROPERTY.BrandId] = brandId;
    const res = await get<AxiosResponse<IAnalyticsConfigEndpoint['response']>>(
      AnalyticsConfigEndpoint.url({
        brandId,
      }),
    ).catch(() => null);

    if (!res) {
      this.isReady = true;
      return;
    }

    const integrations = res.data;

    integrations.forEach((integration) => {
      switch (integration.type) {
        case IntegrationName.TEALIUM_ANALYTICS:
          this.providers.push(new TealiumClient(integration));
          break;
        case IntegrationName.GTM_ANALYTICS:
          this.providers.push(new GTMClient(integration));
          break;
        case IntegrationName.GA4_ANALYTICS:
          this.providers.push(new Ga4Client(integration));
          break;
        default:
          break;
      }
    });

    this.isReady = true;
  }

  setAppData(payload: IAnalyticsDataPayload) {
    Object.assign(this.appData, payload);

    this.observers.forEach((observer) => {
      observer(this.getAppData(), payload);
    });
  }

  getAppData() {
    return Object.freeze({
      ...this.appData,
      [ANALYTICS_PROPERTY.SessionId]: this.sessionId,
      [ANALYTICS_PROPERTY.UserId]: this.userId,
    });
  }

  removeAppData(property: ANALYTICS_PROPERTY) {
    delete this.appData[property];
  }

  initProviders() {
    this.providers.forEach((provider) => {
      provider.init();
      debug('log', `${provider.constructor.name} provider initialized`);
    });

    this.events.forEach((event) => {
      this.triggerEvent(event.eventName, event.payload);
    });
  }

  private sendToWayApi(data: IAnalyticsEventRequestSchema['request']) {
    post<
      IAnalyticsEventRequestSchema['response'],
      IAnalyticsEventRequestSchema['request']
    >(AnalyticsEventEndpoint.url({ brandId: this.appData.brandId || '' }), {
      ...data,
      providers: this.providers
        .reduce((acc, p) => {
          if (p.provider) {
            acc.push(p.provider);
          }
          return acc;
        }, [] as (typeof Integrations)[IntegrationGroup.ANALYTICS][number][])
        .filter((p) => !!p),
    }).catch(() => null);
  }

  private getPayloadData(customPayload?: Record<string, string>) {
    return {
      timestamp: format('MM-dd-yyyy:HH:mm:SS', new Date()),
      ...this.getAppData(),
      ...customPayload,
    };
  }

  private triggerEvent(
    eventName: ANALYTICS_EVENT,
    customPayload?: IAnalyticsDataPayload,
  ) {
    const payload = this.getPayloadData(customPayload);
    this.providers.forEach((p) => p.sendEvent(eventName, payload));
  }

  sendEvent(eventName: ANALYTICS_EVENT, customPayload?: IAnalyticsDataPayload) {
    debug('log', 'Analytics Manager Event', {
      eventName,
      customPayload,
      payload: this.getAppData(),
    });

    const payload = this.getPayloadData(customPayload);

    this.sendToWayApi({
      event: eventName,
      payload,
    });

    if (!this.isReady) {
      this.events.push({
        eventName,
        payload,
      });

      return;
    }

    if (!this.providers.length) {
      return;
    }
    this.triggerEvent(eventName, customPayload);
  }

  addDataObserver(observer: AnalyticsDataObserver) {
    this.observers.push(observer);

    return () => {
      this.observers = this.observers.filter((o) => o !== observer);
    };
  }
}

export const analyticsManager = AnalyticsManager.getInstance();
