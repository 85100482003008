import useSWRInfinite from 'swr/infinite';
import { get } from 'utils/axios';
import type { PaginatedResponse } from '@kouto/types';

const usePaginatedItems = <T = any>(url?: string | null) => {
  const { data, error, mutate, size, setSize } = useSWRInfinite<
    PaginatedResponse<T>
  >((index: number) => {
    const separator = url?.includes('?') ? '&' : '?';
    return url ? `${url}${separator}page=${index + 1}` : null;
  }, get);

  const items =
    data && data[0]?.items?.length ? data.map((d) => d.items).flat() : [];

  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && !!data && typeof data[size - 1] === 'undefined');
  const isEmpty = data ? data[0]?.items?.length === 0 : true;
  const allItemsLoaded =
    !!data && !data[0].meta
      ? true
      : !!data && data[0].meta && data[0]?.meta?.totalItems === items.length;

  const loadMore = () => setSize(size + 1);

  return {
    items,
    error,
    meta: data ? data[0].meta : undefined,
    isEmpty,
    isLoadingInitialData,
    isLoadingMore,
    loadMore,
    allItemsLoaded,
    mutate,
  };
};

export default usePaginatedItems;
