import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import { FormProvider, useFieldArray, useForm } from 'hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Wrapper } from 'components/theme/Wrapper';
import { useAppState, useBrandId, useDispatch } from 'AppProvider';
import {
  ListingKind,
  ANALYTICS_EVENT,
  ListingAddOnLimitType,
} from '@kouto/types';
import { fetchBrandTerms } from 'actions/brand';
import { BookingMode } from 'types/payment';
import { CartItemState } from 'types/cart';
import { P as Paragraph } from 'components/theme/Typography/Typography';
import { SkeletonLine } from 'components/theme/Skeleton/SkeletonLine';
import { NavigationHeader } from 'components/NavigationHeader';
import { removeExperienceFromCart } from 'actions/cart';
import useSearchQueryParams from 'hooks/use-search-params';
import useCustomQuestions from 'hooks/useCustomQuestions';
import { useCartItemId } from 'features/ShoppingCart/hooks';
import { ParticipantField } from 'features/ShoppingCart/components/ParticipantField';
import {
  IParticipantConfig,
  ISelectOptions,
} from 'features/ShoppingCart/types';
import { useCart } from 'hooks/useCart';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'components/theme/Button/Button';
import { decodeHTML } from 'utils';
import { useToastContext } from 'ToastProvider';
import { getExperienceSettings } from 'actions/experience';
import * as Styled from 'features/ShoppingCart/utils/styles';
import useFetchExperience from 'selectors/experience';
import { QUESTION_SCOPES } from 'types/custom-questions';
import CustomQuestions from 'components/CustomQuestion/CustomQuestions';
import { STATUS_PENDING } from 'types/app';
import { usePageViewEvent } from 'hooks/usePageViewEvent';
import { useExperienceAnalyticsData } from 'features/analytics/hooks/useExperienceAnalyticsData';
import ListingCartItemSummary from 'features/ShoppingCart/components/ListingCartItemSummary';
import { useEmbedConfig } from 'features/EmbedConfig';
import useOneCartItemListing from 'hooks/useOneCartItemListing';

const formatFullName = (name: string | ISelectOptions) => {
  if (typeof name === 'string') {
    return name.trim();
  }
  return {
    label: name?.label.trim(),
    value: name?.value,
  };
};

const ParticipantsForm = () => {
  const { maxFieldCapacity, onEditCart } = useCart();
  const { currentExperienceDetail } = useExperienceAnalyticsData();
  const { purchaserParentQuestions } = useCustomQuestions();
  const cartItemId = useCartItemId();
  const dispatch = useDispatch();
  const brandId = useBrandId();
  const methods = useForm<IParticipantConfig>({ mode: 'onChange' });
  const { cartItem, listing, isLoading } = useOneCartItemListing(cartItemId);
  const history = useHistory();
  const { t: translateText } = useTranslation();
  const { searchParams } = useSearchQueryParams();
  const { settings } = useAppState(
    (state: Record<string, unknown>) => state.brand,
  );
  const { showToast } = useToastContext();
  const { experience, fetchSettingsStatus } = useFetchExperience();
  usePageViewEvent({
    eventName: ANALYTICS_EVENT.VIEW_PARTICIPANT_INFO,
    isNotReady: !currentExperienceDetail?.id,
  });
  const embedConfig = useEmbedConfig();

  const {
    fields,
    append,
    remove: removeField,
  } = useFieldArray({
    control: methods.control,
    name: 'participants',
  });

  const populateFormFields = () => {
    if (!cartItemId || !cartItem || fields.length >= 1) return;
    append(
      cartItem.participants.map((p) => ({
        fullName: p.fullName || '',
        terms: p.terms,
        priceTierName: p.priceTierName,
        price: p.price,
      })),
      {
        focusIndex: 0,
      },
    );
    methods.setValue(
      'additionalCustomQuestionResponses',
      cartItem.additionalCustomQuestionResponses,
    );
  };

  useEffect(() => {
    if (!experience.loading && brandId) {
      dispatch(fetchBrandTerms(brandId));
      populateFormFields();
      dispatch(getExperienceSettings(brandId, experience?.id));
    }
  }, [experience.id, experience.loading, brandId]);

  const cartItemContainsMultiplePriceTiers = useMemo(
    () =>
      new Set(
        cartItem?.participants?.map((participant) => participant.priceTierName),
      ).size > 1,
    [cartItem],
  );

  const canAddParticipants = useMemo(() => {
    const eligibleSize = cartItemId
      ? experience?.partySize - fields.length
      : experience?.partySize -
        fields.length -
        (cartItem?.participants?.length ?? 0);

    const isSlotSizeMet =
      searchParams.mode === BookingMode.SHARED &&
      (eligibleSize === 0 || fields.length === maxFieldCapacity);

    const selectedPriceTier = experience?.priceTiers?.find(
      (pt: any) => pt.name === cartItem?.participants[0]?.priceTierName,
    );

    return (
      !isSlotSizeMet &&
      (Number.isNaN(Number(experience?.maxParticipantsCount)) ||
        fields.length < experience?.maxParticipantsCount) &&
      !cartItem?.waitlistEntryId &&
      !cartItemContainsMultiplePriceTiers &&
      (!selectedPriceTier || selectedPriceTier?.maxQuantity > fields.length) &&
      purchaserParentQuestions.length === 0
    );
  }, [
    cartItemId,
    cartItem,
    experience,
    maxFieldCapacity,
    fields,
    purchaserParentQuestions,
    searchParams,
    cartItemContainsMultiplePriceTiers,
  ]);

  if (cartItemId && !cartItem && !isLoading) {
    return (
      <Wrapper>
        <NavigationHeader />
      </Wrapper>
    );
  }

  const participantsInfoMapper = (
    participants: IParticipantConfig['participants'],
  ) => {
    return participants.map((p) => {
      const name = isObject(p.fullName) ? p.fullName.label : p.fullName;
      const splittedName = name.split(' ');
      return {
        ...p,
        fullName: name,
        firstName: splittedName?.[0] || '',
        lastName: splittedName?.slice(1).join(' ') || '',
      };
    });
  };

  const updateCartItemAndRedirect = async (
    data: IParticipantConfig,
    route: string,
    overrideSearch?: string,
  ) => {
    if (cartItem) {
      let search = overrideSearch;

      if (!search) {
        const purchaserParams = new URLSearchParams();
        if (searchParams.firstName) {
          purchaserParams.append('firstName', searchParams.firstName);
        }
        if (searchParams.lastName) {
          purchaserParams.append('lastName', searchParams.lastName);
        }
        if (searchParams.emailAddress) {
          purchaserParams.append('emailAddress', searchParams.emailAddress);
        }
        if (searchParams.phoneNumber) {
          purchaserParams.append('phoneNumber', searchParams.phoneNumber);
        }
        search = purchaserParams.toString();
      }

      onEditCart(
        {
          ...cartItem,
          ...data,
          state: CartItemState.READY,
          participants: participantsInfoMapper(data.participants),
        },
        route,
        search,
      );
    }
  };

  const onDeleteFromCart = () => {
    if (cartItemId) {
      dispatch(
        removeExperienceFromCart({
          cartItemId,
          persistMeta: {
            brandId,
          },
        }),
      );
      history.push('/e');
      showToast({
        title: 'Success',
        message: 'Item removed from cart',
      });
    }
  };

  const onAddParticipants = () => {
    append({
      fullName: embedConfig?.participantName?.[fields.length] || '',
      terms: false,
      priceTierName:
        cartItem?.participants[0]?.priceTierName ??
        get(experience, 'priceTiers[0].name'),
      price:
        cartItem?.participants[0]?.price ??
        get(experience, 'priceTiers[0].price'),
    });
  };

  const onDeleteParticipant = (participantIndex: number) => {
    removeField(participantIndex);
    const newParticipantsCount = fields.length - 1;

    if (cartItem && cartItem.addOns) {
      cartItem.addOns = cartItem.addOns.map((addOn) => {
        const litingAddOn = listing?.addOns?.find(
          (listingAddOn) => listingAddOn.id === addOn.id,
        );

        if (litingAddOn && litingAddOn.limit) {
          const listingLimit =
            litingAddOn.limit.type === ListingAddOnLimitType.PER_BOOKING
              ? litingAddOn.limit.value
              : litingAddOn.limit.value * newParticipantsCount;

          return {
            ...addOn,
            selectedNumber: Math.min(addOn.selectedNumber, listingLimit),
          };
        }

        return addOn;
      });

      onEditCart(cartItem);
    }
  };

  const handleSubmit = (redirectPath: string, retry = false) => {
    return methods.handleSubmit(
      (data) => {
        const dataCopy = {
          ...data,
          participants: data.participants.map((participant) => ({
            ...participant,
            fullName:
              searchParams.skipParticipantsName === 'true'
                ? 'Resource Purchaser'
                : formatFullName(participant.fullName),
          })),
        };

        if (cartItem?.listingId) {
          dataCopy.listingId = cartItem.listingId;
        }
        if (cartItem?.groupId) {
          dataCopy.groupId = cartItem.groupId;
          const resourceGroup = listing?.resourceGroups?.find(
            (rg) => rg.id === cartItem.groupId,
          );
          if (resourceGroup) {
            dataCopy.groupTitle = resourceGroup.title;
          }
        }

        if (redirectPath !== '/checkout') {
          if (listing && listing.kind !== ListingKind.EXPERIENCE) {
            return updateCartItemAndRedirect(
              dataCopy,
              `/${
                listing.kind === ListingKind.RESOURCE ? 'collection' : 'event'
              }/${listing.id}`,
              `date=${searchParams.date}`,
            );
          }
        }
        return updateCartItemAndRedirect(dataCopy, redirectPath);
      },
      (errors, ev) => {
        // Handle weird react-hook-form bug where participants error array is empty
        // https://github.com/react-hook-form/react-hook-form/issues/9902
        const { participants: participantsErrors } = errors;
        if (
          !retry &&
          Array.isArray(participantsErrors) &&
          !participantsErrors.find((err) => !!err)
        ) {
          methods.clearErrors();
          requestAnimationFrame(() => handleSubmit(redirectPath, true)(ev));
        }
      },
    );
  };

  return (
    <Wrapper>
      <Helmet>
        {experience?.title && (
          <title>
            {experience?.title} | Details | {settings?.name}
          </title>
        )}
      </Helmet>
      <NavigationHeader />
      {!!cartItem?.participants && cartItem?.participants.length > 0 && (
        <PageLayout>
          <div>
            {experience?.loading || fetchSettingsStatus === STATUS_PENDING ? (
              <>
                <SkeletonLine style={{ height: 100 }} translucent />
                <SkeletonLine style={{ height: 300 }} translucent />
              </>
            ) : (
              <>
                <Styled.HeadTitle>
                  {translateText(
                    cartItemId
                      ? 'editParticipantsDetails'
                      : 'participantDetails',
                  )}
                </Styled.HeadTitle>
                <Paragraph>
                  {translateText('enterParticipantInfoText')}
                </Paragraph>
                <FormProvider {...methods}>
                  <Styled.FormSection>
                    {searchParams.skipParticipantsName === 'true'
                      ? null
                      : fields.map((field, i) => {
                          return (
                            <ParticipantField
                              idx={i}
                              priceTierName={field.priceTierName}
                              onRemove={() => onDeleteParticipant(i)}
                              fieldsLength={fields.length}
                              key={field.id}
                            />
                          );
                        })}
                    {canAddParticipants && (
                      <AddParticipantContainer>
                        <PrimaryButton
                          className="cart__add-participant-button"
                          type="button"
                          onClick={onAddParticipants}
                        >
                          {decodeHTML(
                            translateText('addParticipant', {
                              tierLabel:
                                cartItem?.participants[0]?.priceTierName,
                            }),
                          )}
                        </PrimaryButton>
                      </AddParticipantContainer>
                    )}
                    <CustomQuestions
                      scope={QUESTION_SCOPES.PURCHASER}
                      formStatePath="additionalCustomQuestionResponses"
                    />
                    {cartItem?.state === CartItemState.READY ? (
                      <Styled.FooterButtons>
                        <PrimaryButton
                          big
                          className="cart__form-button-full cart__save-changes-button"
                          onClick={handleSubmit('/checkout')}
                        >
                          {translateText('saveChanges')}
                        </PrimaryButton>
                        <SecondaryButton
                          big
                          className="cart__form-button-clear cart__cancel-changes-button"
                          onClick={() => history.push('/checkout')}
                        >
                          {translateText('cancelChanges')}
                        </SecondaryButton>
                      </Styled.FooterButtons>
                    ) : (
                      <Styled.FooterButtons>
                        <PrimaryButton
                          big
                          className="cart__form-button-full cart__proceed-to-checkout-button"
                          disabled={
                            String(searchParams.latest).toLowerCase() === 'true'
                          }
                          onClick={handleSubmit('/checkout')}
                          analyticEvent={
                            ANALYTICS_EVENT.CLICK_PROCEED_TO_CHECKOUT_BUTTON
                          }
                        >
                          {translateText('proceedToCheckout')}
                        </PrimaryButton>
                        <SecondaryButton
                          big
                          className="cart__form-button-clear cart__add-to-cart-button"
                          disabled={
                            String(searchParams.latest).toLowerCase() === 'true'
                          }
                          onClick={handleSubmit('/e')}
                          analyticEvent={
                            ANALYTICS_EVENT.CLICK_ADD_TO_CART_BUTTON
                          }
                        >
                          {translateText('addToCart')}
                        </SecondaryButton>
                      </Styled.FooterButtons>
                    )}
                  </Styled.FormSection>
                </FormProvider>
              </>
            )}
          </div>
          <div>
            {cartItem?.state === CartItemState.READY && (
              <PrimaryButton
                big
                className="cart__form-button-full cart__remove-from-reservation-button"
                onClick={onDeleteFromCart}
                style={{ marginBottom: '20px' }}
              >
                {translateText('removeFromReservation')}
              </PrimaryButton>
            )}

            {cartItem && listing && (
              <ListingCartItemSummary
                cartItem={cartItem}
                listing={listing}
                overriddenParticipants={
                  searchParams.skipParticipantsName === 'true' &&
                  fields.length > 0
                    ? undefined
                    : fields.map((p) => ({
                        firstName: '',
                        lastName: '',
                        fullName: '',
                        priceTierName: p.priceTierName,
                        price: p.price,
                        terms: p.terms,
                        customQuestionResponses:
                          p?.customQuestionResponses ?? {},
                      }))
                }
              />
            )}
          </div>
        </PageLayout>
      )}
    </Wrapper>
  );
};

const AddParticipantContainer = styled.div`
  margin-bottom: 60px;
`;

const PageLayout = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  gap: 36px;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  & > div:first-child {
    flex-grow: 1;
    max-width: 720px;

    @media (max-width: 768px) {
      max-width: unset;
    }
  }
  & > div:last-child {
    min-width: 350px;
  }
`;

export default ParticipantsForm;
