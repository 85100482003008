export default {
  buyTickets: 'Tickets kaufen',
  salesEnded: 'Verkauf beendet',
  joinWaitlist: 'Zur Warteliste hinzufügen',
  soldOut: 'Ausverkauft',
  noDatesAvailable: 'Keine Termine verfügbar',
  seeMoreDates: 'Weitere Termine anzeigen',
  about: 'Über',
  agenda: 'Tagesordnung',
  menu: 'Menü',
  included: 'Inbegriffen',
  viewMore: 'Mehr anzeigen',
  viewLess: 'Weniger anzeigen',
  location: 'Ort',
  time: 'Zeit',
  at: 'um',
  noTimesAvailableOnThisDateFotThisPartySize:
    'Keine Zeiten verfügbar an diesem Datum für diese Gruppengröße',
  availableTimes: 'Verfügbare Zeiten',
  waitlist: 'Warteliste',
  continue: 'Weiter',
  ticketsAreNoLongerAvailable: 'Tickets sind nicht mehr verfügbar.',
  checkOutOutOtherExperiences: 'Schauen Sie sich unsere anderen Erlebnisse an!',
  viewExperiences: 'Erlebnisse anzeigen',
  from: 'ab',
  cancellationPolicy: 'Stornierungsbedingungen',
  rsvp: 'RSVP',
};
