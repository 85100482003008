import styled from 'styled-components';
import { boolean } from 'zod';

export const PaymentOptions = styled.div``;

export const PaymentMethodsContainer = styled.div``;

export const PaymentParagraph = styled.p`
  margin-bottom: 10px;
`;

export const AccordionHeader = styled.div<{ primaryColor?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  & > input {
    flex: 1;
    width: 100%;
    border-radius: var(--way-design-borderRadiusDefault);
    border: 0.5px solid var(--way-colors-borderColor);
    padding: 5px 12px;
    font-size: 14px;
    line-height: 22px;

    &:focus {
      outline: none;
    }
  }
`;

export const RoomChargeAccordionContent = styled.div`
  padding-top: 10px;
  display: flex;
  align-items: center;

  & > input:nth-child(2) {
    margin-left: 15px;
  }

  & > * {
    max-height: none;
  }
`;

export const AccordionContent = styled.div`
  padding-top: 10px;

  & > * {
    max-height: none;
  }
`;

export const PaymentAccordionItem = styled.div`
  padding: 10px 0px;
  border-bottom: 0.5px solid var(--way-colors-borderColor);
`;

export const PaymentErrorContainer = styled.div`
  min-height: 20px;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: inherit;
  margin-left: 8px;
`;

export const Icon = styled.span<{ isActive: boolean }>`
  & > * {
    transition: transform 0.3s ease-in-out;
    transform: rotate(-180deg) scale(0.9);
    transform: ${({ isActive }) =>
      isActive ? 'scale(0.9)' : 'rotate(-180deg) scale(0.9)'};
  }
`;

export const CardStripeWrapper = styled.div<{ withPadding: boolean }>`
  width: 100%;
  ${({ withPadding }) => (withPadding ? 'padding: 12px;' : '')}
  font-family: 'Source Code Pro', monospace;
  border-radius: var(--way-design-borderRadiusDefault);
`;

export const StyledInput = styled.input`
  width: '100%';
  flex: 1;
  border-radius: var(--way-design-borderRadiusDefault);
  padding: 5px 12px;
  font-size: 14px;
  height: 42px;

  color: var(--way-colors-inputColor);
  &:disabled {
    color: var(--way-colors-disabledInput);
  }
  border: 0.5px solid var(--way-colors-borderColor);
  &:not(:disabled):not(:focus):hover {
    border: 0.5px solid var(--way-colors-borderHoverColor);
  }
  &:focus {
    outline: none;
    border: 0.5px solid var(--way-colors-borderSelectedColor);
  }
`;
