export default {
  buyTickets: 'Купить билеты',
  salesEnded: 'Продажа завершена',
  joinWaitlist: 'Присоединиться к списку ожидания',
  soldOut: 'Продано',
  noDatesAvailable: 'Нет доступных дат',
  seeMoreDates: 'Посмотреть больше дат',
  about: 'О нас',
  agenda: 'Повестка дня',
  menu: 'Меню',
  included: 'Включено',
  viewMore: 'Посмотреть больше',
  viewLess: 'Посмотреть меньше',
  location: 'Местоположение',
  time: 'Время',
  at: 'в',
  noTimesAvailableOnThisDateFotThisPartySize:
    'Нет доступного времени на эту дату для этого размера группы',
  availableTimes: 'Доступное время',
  waitlist: 'Список ожидания',
  continue: 'Продолжить',
  ticketsAreNoLongerAvailable: 'Билеты больше не доступны.',
  checkOutOutOtherExperiences: 'Посмотрите наши другие впечатления!',
  viewExperiences: 'Посмотреть впечатления',
  from: 'от',
  cancellationPolicy: 'Политика отмены',
  rsvp: 'RSVP',
};
