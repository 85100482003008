export default {
  buyTickets: 'Compra i biglietti',
  salesEnded: 'Vendite terminate',
  joinWaitlist: "Unisciti alla lista d'attesa",
  soldOut: 'Esaurito',
  noDatesAvailable: 'Nessuna data disponibile',
  seeMoreDates: 'Vedi altre date',
  about: 'Informazioni',
  agenda: 'Programma',
  menu: 'Menu',
  included: 'Incluso',
  viewMore: 'Vedi di più',
  viewLess: 'Vedi meno',
  location: 'Posizione',
  time: 'Orario',
  at: 'a',
  noTimesAvailableOnThisDateFotThisPartySize:
    'Nessun orario disponibile in questa data per questo numero di persone',
  availableTimes: 'Orari disponibili',
  waitlist: "Lista d'attesa",
  continue: 'Continua',
  ticketsAreNoLongerAvailable: 'I biglietti non sono più disponibili.',
  checkOutOutOtherExperiences: 'Scopri le nostre altre esperienze!',
  viewExperiences: 'Vedi esperienze',
  from: 'da',
  cancellationPolicy: 'Politica di cancellazione',
  rsvp: 'RSVP',
};
