import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { currencyFormat } from 'utils';
import { Sep } from 'components/common/styled/common-styled';
import { useAppState } from 'AppProvider';
import InfoIcon from 'assets/bx-info-circle';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import { useTranslation } from 'react-i18next';
import { GuestCancellationBookingStatus } from '@kouto/types';
import { CancellationBooking } from '../useGuestCancellation';
import BookingCancellationCard from './BookingCancellationCard';

type Props = {
  selectedBooking: CancellationBooking;
};

const ReviewCancellationStep: FC<Props> = ({ selectedBooking }) => {
  const { t } = useTranslation();
  const currency = useBrandCurrency();
  const brandSettings = useAppState((state: any) => state.brand.settings);
  const currencyFormatter = useMemo(() => currencyFormat(currency), [currency]);

  return (
    <>
      <BookingsCancellationList>
        <BookingCancellationCard booking={selectedBooking} selected />
      </BookingsCancellationList>

      {selectedBooking.status === GuestCancellationBookingStatus.CANCELLABLE ? (
        <CancellationInfo>
          <InfoRow>
            <Label>{t('youPaid')}</Label>
            <PriceLabel data-testid="youPaidAmount">
              {currencyFormatter(selectedBooking.totalAmount)}
            </PriceLabel>
          </InfoRow>

          {selectedBooking.previouslyRefundedAmount > 0 && (
            <>
              <InfoRow>
                <Label>{t('previouslyRefunded')}</Label>
                <PriceLabel data-testid="previouslyRefunded">
                  ({currencyFormatter(selectedBooking.previouslyRefundedAmount)}
                  )
                </PriceLabel>
              </InfoRow>
              <Sep />
              <InfoRow>
                <Label>{t('remainingBalance')}</Label>
                <PriceLabel data-testid="remainingBalance">
                  {currencyFormatter(selectedBooking.remainingBalance)}
                </PriceLabel>
              </InfoRow>
            </>
          )}

          <InfoRow>
            <Label>{t('refund')} %</Label>
            <PriceLabel data-testid="refundPercentage">
              {selectedBooking.refundPercentage}%
            </PriceLabel>
          </InfoRow>
          <InfoRow>
            <Label>{t('yourRefund')}</Label>
            <PriceLabel refundAmount data-testid="yourRefundAmount">
              {currencyFormatter(selectedBooking.refundAmount)}
            </PriceLabel>
          </InfoRow>
        </CancellationInfo>
      ) : (
        <CancellationMessage>
          <InfoIcon scale={1.4} color="var(--way-colors-primaryTextColor)" />
          <Label>
            {getErrorMessageByStatus(selectedBooking.status, brandSettings, t)}
          </Label>
        </CancellationMessage>
      )}
    </>
  );
};

export default ReviewCancellationStep;

const getErrorMessageByStatus = (
  status: GuestCancellationBookingStatus,
  brandSettings: Record<string, unknown>,
  t: (s: string, op?: Record<string, string>) => string,
) => {
  if (status === GuestCancellationBookingStatus.DEADLINE_PASSED) {
    return t('thisReservationCanNoLongerBeCancelledPleaseEmail', {
      brandEmail:
        typeof brandSettings?.brandEmail === 'string'
          ? brandSettings?.brandEmail
          : t('theHotel'),
    });
  }
  if (status === GuestCancellationBookingStatus.UNCANCELLABLE) {
    return t('thisReservationCannotBeCancelled');
  }
  if (status === GuestCancellationBookingStatus.CANCELLED) {
    return t('thisReservationHasAlreadyBeenCancelled');
  }
  return '';
};

const BookingsCancellationList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 32px;
`;

const CancellationInfo = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  border: 0.5px solid var(--way-colors-borderColor);
  margin-bottom: 32px;
`;

const CancellationMessage = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  border: 0.5px solid var(--way-colors-borderColor);
  margin-bottom: 32px;
  align-items: center;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.span`
  /* skip css namespace */
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: var(--way-colors-contrastColorShades-60);
`;

const PriceLabel = styled.span<{ refundAmount?: boolean }>`
  /* skip css namespace */
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: ${({ refundAmount }) =>
    refundAmount ? '#4AA133' : 'var(--way-colors-secondaryTextColor)'};
`;
