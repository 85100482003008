export default {
  buyTickets: '티켓 구매',
  salesEnded: '판매 종료',
  joinWaitlist: '대기자 명단에 가입',
  soldOut: '매진',
  noDatesAvailable: '사용 가능한 날짜가 없습니다',
  seeMoreDates: '더 많은 날짜 보기',
  about: '소개',
  agenda: '일정',
  menu: '메뉴',
  included: '포함됨',
  viewMore: '더보기',
  viewLess: '덜보기',
  location: '위치',
  time: '시간',
  at: '에',
  noTimesAvailableOnThisDateFotThisPartySize:
    '이 날짜에는 이 파티 크기에 사용할 수 있는 시간이 없습니다',
  availableTimes: '사용 가능한 시간',
  waitlist: '대기자 명단',
  continue: '계속',
  ticketsAreNoLongerAvailable: '티켓을 더 이상 사용할 수 없습니다.',
  checkOutOutOtherExperiences: '다른 경험을 확인하세요!',
  viewExperiences: '경험 보기',
  from: '부터',
  cancellationPolicy: '취소 정책',
  rsvp: 'RSVP',
};
