import React, { FC, useMemo, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Flex, Sep } from 'components/common/styled/common-styled';
import { ButtonBlock } from 'components/theme/Button/Button';
import { ResourceParagraph } from 'components/theme/Typography/ResourceTypography';
import { ResourceGroup, Session } from 'types/listings';
import { getSessionStartEndTime } from 'utils/formatDuration';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import { useForm, FormContext } from 'react-hook-form';
import {
  ResourceCustomQuestionProps,
  ResourceCustomQuestions,
} from 'features/ResourceBooking/components/ResourceCustomQuestions';
import useResourceBookingSession from 'features/Reserve/hooks/useResourceBookingSession';
import {
  BottomDrawer,
  BottomDrawerProps,
} from 'components/BottomDrawer/BottomDrawer';
import { useHistory } from 'react-router-dom';
import ResourceMetaMobileView from './ResourceMeta/ResourceMetaMobileView';
import { PriceTierSelector } from '../Booking/PriceTier/PriceTierSelector';
import { PriceTierWithParticipants } from '../Booking/PriceTier/types';
import { IParticipantConfig } from '../../features/ShoppingCart/types';
import { DATE_FORMAT, serializeParams, TIME_FORMAT } from '../../utils';
import { useTierParticipants } from '../../hooks/use-selected-participants';
import { BOOKING_MODES } from '../../types/booking';
import {
  integrateSessionParticipants,
  setTierParticipants,
} from '../../actions/participants';
import { filterEmpty } from '../../actions/helpers';
import useSearchQueryParams from '../../hooks/use-search-params';
import useBrandToggleFeature from '../BrandToggleFeature/use-brand-toggle-feature';
import { useDispatch } from '../../AppProvider';

interface Props extends BottomDrawerProps {
  collectionId: string;
  group: ResourceGroup;
  priceTiers: PriceTierWithParticipants[];
  selectedSession: Session;
  sessions: Session[];
  onPriceTierChange: (
    tierId: PriceTierWithParticipants['id'],
    increment: number,
  ) => void;
}

export const BottomSheetNonMappedResourcePage: FC<Props> = ({
  collectionId,
  group,
  priceTiers,
  selectedSession,
  sessions,
  onPriceTierChange,
  ...props
}) => {
  const currency = useBrandCurrency();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const { latest } = useResourceBookingSession();

  const [
    additionalCustomQuestionResponses,
    setAdditionalCustomQuestionResponses,
  ] = useState({} as IParticipantConfig['additionalCustomQuestionResponses']);

  const onCustomQuestionsChange: ResourceCustomQuestionProps['onChange'] = (
    data,
  ) => {
    setAdditionalCustomQuestionResponses(data);
  };

  const formMethods = useForm<IParticipantConfig>({ mode: 'onChange' });

  const resource = useMemo(() => {
    return group.experiences[0];
  }, [group.experiences]);

  const customQuestions = useMemo(
    () =>
      resource.customQuestions.filter((question) => !question.config.isDefault),
    [resource],
  );

  const { startTime, endTime } = useMemo(() => {
    return getSessionStartEndTime(selectedSession);
  }, [selectedSession]);

  const maxParticipants = useMemo(() => {
    if (selectedSession) {
      return sessions.filter(
        (session) =>
          selectedSession.startDateTime === session.startDateTime &&
          selectedSession.duration === session.duration,
      ).length;
    }

    return 0;
  }, [sessions, selectedSession]);

  const selectedParticipants = useMemo(() => {
    return priceTiers.reduce((sum, tier) => {
      return sum + tier.participants;
    }, 0);
  }, [priceTiers]);

  const [_, setSelectedTierCustomers] = useTierParticipants();
  const isCartFeatureEnabled = useBrandToggleFeature('shoppingCart');
  const { searchParams } = useSearchQueryParams();

  if (!currency) {
    return null;
  }

  const getSelectedParticipants = async () => {
    const noOfGuests = priceTiers.reduce(
      (sum, data) => sum + data.participants,
      0,
    );
    if (noOfGuests === 0) {
      return null;
    }

    formMethods.setValue(
      'additionalCustomQuestionResponses',
      additionalCustomQuestionResponses,
    );

    const isValid = await formMethods.triggerValidation();

    if (!isValid) {
      return null;
    }

    const selectedParticipants = priceTiers.flatMap((t) =>
      !t.participants
        ? []
        : [
            {
              ...t,
              selectedNumber: t.participants,
            },
          ],
    );

    const basicCartData = {
      sessionDate: moment(selectedSession.startDateTime).format(DATE_FORMAT),
      sessionTime: moment(selectedSession.startDateTime).format(TIME_FORMAT),
      duration: moment
        .duration(selectedSession.duration, 'minutes')
        .toISOString(),
      groupId: group.id,
      listingId: collectionId,
    };

    return { selectedParticipants, noOfGuests, basicCartData };
  };

  const onCheckout = async () => {
    const data = await getSelectedParticipants();

    if (!data) {
      return;
    }

    const { selectedParticipants, basicCartData } = data;

    const bookingData = {
      mode: BOOKING_MODES.SHARED,
      ...basicCartData,
      supportedParticipantsCount: group.experiences.length,
      ...(isCartFeatureEnabled ? searchParams : {}),
    };

    setSelectedTierCustomers({
      selectedParticipants,
      currentExperience: resource.id,
    });

    dispatch(
      integrateSessionParticipants({
        session: selectedSession,
      }),
    );

    dispatch(
      setTierParticipants({
        participants: selectedParticipants,
        currentExperience: resource.id,
      }),
    );

    if (latest) {
      bookingData.latest = true;
    }
    const filteredBookingData = filterEmpty(bookingData);

    history.push({
      pathname: `/e/${resource.id}/${
        isCartFeatureEnabled ? 'participants' : 'booking'
      }`,
      search: serializeParams(filteredBookingData),
    });
  };

  return (
    <FormContext {...formMethods}>
      <BottomDrawer {...props}>
        <Flex
          direction="column"
          alignItem="flex-start"
          justifyContent="flex-start"
          gap={24}
        >
          <Flex
            direction="column"
            gap={16}
            alignItem="flex-start"
            justifyContent="flex-start"
            width="100%"
          >
            <ButtonWrapper
              direction="row"
              gap={16}
              alignItem="stretch"
              justifyContent="flex-start"
              width="100%"
            >
              <ButtonBlock onClick={onCheckout}>
                {translate('reserve')}
              </ButtonBlock>
            </ButtonWrapper>
          </Flex>
          <ResourceMetaMobileView
            metaLabel="Date"
            value={moment(selectedSession.startDateTime).format('MMMM D, YYYY')}
          />
          <ResourceMetaMobileView
            metaLabel={translate('time')}
            value={`${startTime} - ${endTime}`}
          />
          <Sep />
          <Flex
            direction="column"
            alignItem="flex-start"
            justifyContent="flex-start"
            gap={16}
          >
            <ResourceParagraph
              className="resource-page-bottom-sheet__selected-tiers"
              fontWeight="350"
              lineHeight="normal"
            >
              {translate('tier')}
            </ResourceParagraph>
            <div>
              {priceTiers.map((priceTier) => (
                <PriceTierSelector
                  key={priceTier.id + priceTier.name + priceTier.price}
                  priceTier={priceTier}
                  maxParticipants={
                    maxParticipants -
                    selectedParticipants +
                    priceTier.participants
                  }
                  currency={currency.code}
                  onChange={(increment) =>
                    onPriceTierChange(priceTier.id, increment)
                  }
                />
              ))}
            </div>
          </Flex>
          <Sep />
          {customQuestions.length > 0 && (
            <CustomQuestionsWrapper
              direction="column"
              alignItem="flex-start"
              justifyContent="flex-start"
              gap={16}
              width="100%"
              className="resource-page-bottom-sheet__questions-wrapper"
            >
              <ResourceParagraph
                className="resource-page-bottom-sheet__questions-heading"
                fontWeight="350"
                lineHeight="normal"
              >
                {translate('questions')}
              </ResourceParagraph>
              <ResourceCustomQuestions
                questions={customQuestions}
                defaultValues={{}}
                experienceId={resource.id}
                onChange={onCustomQuestionsChange}
              />
            </CustomQuestionsWrapper>
          )}
        </Flex>
      </BottomDrawer>
    </FormContext>
  );
};

const CustomQuestionsWrapper = styled(Flex)`
  &.resource-page-bottom-sheet__questions-wrapper form {
    width: 100%;
  }
`;

const ButtonWrapper = styled(Flex)`
  #kouto-embed-root && {
    margin-top: 24px;

    & > button {
      flex: 1;
      padding: 10px 12px;
      height: 48px;
    }
  }
`;
